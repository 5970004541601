import { useState } from "react";
import { Wrapper, SelectBox, WrapperTop, WrapperBottom } from "./styles";

import { DropDown } from "components/atoms/DropDown";

import { locations } from "./data";

const LocationSelect = ({setLocation}) => {
    const [isOpen, setOpen] = useState(false);
    const [activeLocation, setActiveLocation] = useState({
        country: 'Poland',
        city: 'Warsaw HQ'
    });
    
    const changeLocationInfo = (location) => {
        setActiveLocation(location);
        setOpen(false);
        setLocation(location);
    }

    const closeDropdown = () => setOpen(false);

    return (
        <Wrapper>
            <SelectBox onClick={() => setOpen(true)}>
                <WrapperTop>
                    <p>Select office:</p>
                </WrapperTop>
                <WrapperBottom>
                    <p>{activeLocation.city}</p>
                    <p>{activeLocation.country}</p>
                    <p>↓</p>
                </WrapperBottom>
            </SelectBox>
            {isOpen &&
                <DropDown 
                    locations={locations}
                    activeLocation={activeLocation}
                    closeDropdown={closeDropdown}
                    changeActiveElement={(location) => changeLocationInfo(location)}
                />
            }
        </Wrapper>
    );
};

export { LocationSelect };