const initialState = {
    isContactFormShow: false,
}

const rootReducer = (state = initialState, action) => {
    switch(action.type) {
        case 'TOGGLE_CONTACT_FORM':
            return {
                ...state,
                isContactFormShow: action.payload.isContactFormShow,
            }
        default:
            return state;
    }
}

export default rootReducer;