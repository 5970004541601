import { AnimationOnScroll } from 'react-animation-on-scroll';
import { Wrapper, Back, Content } from "./styles";

import ShadowMobileBottom from 'assets/images/shadows/shadow-mobile-bottom.png';
import ShadowMobileTop from 'assets/images/shadows/shadow-mobile-top.png';
import ShadowDesktopBottom from 'assets/images/shadows/shadow-desktop-bottom.png';
import ShadowDesktopTop from 'assets/images/shadows/shadow-desktop-top.png';
import { Link } from "react-router-dom";

const Parallax = ({title, content, link_text, link}) => (
    <Wrapper>
        <img className="mobile top" alt="mobile shadow" src={ShadowMobileTop} />
        <img className="mobile bottom" alt="mobile shadow" src={ShadowMobileBottom} />
        <img className="desktop top" alt="desktop shadow" src={ShadowDesktopTop} />
        <img className="desktop bottom" alt="desktop shadow" src={ShadowDesktopBottom} />
        <Back>
            <AnimationOnScroll animateOnce={true} animateIn="animate__fadeInUp" duration={1.5}>
                <Content>
                    <h4>{title}</h4>
                    <div>
                        <p>{content}</p>
                        <Link to={link}>{link_text}</Link>
                    </div>
                </Content>
            </AnimationOnScroll>
        </Back>
    </Wrapper>
);

export { Parallax };
