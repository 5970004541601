import styled from 'styled-components';
import { media } from 'themes/media';

const Wrapper = styled.div`
    width: 100%;
    margin: 0 0 96px;
    box-sizing: border-box;
    ${media.up('lg')}{
        display: flex;
        justify-content: space-between;
    }
`;

const LocationSelect = styled.div`
    width: 100%;
    margin: 0 0 51px;
    ${media.up('lg')}{
        max-width: 387px;
        order: 2;
    }
`;

const LocationList = styled.div`
    width: 100%;
    margin: 0;
    display: flex;
    flex-wrap: wrap;
    gap: 26px;
    ${media.up('lg')}{
        width: 50%;
        gap: 24px;
    }
`;

const SoloLocation = styled.div`
    width: 100%;
    h4{
        display: none;
    }
    p{
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0em;
        text-align: left;
        margin: 0;
        padding: 0;
        max-width: auto;
    }
    ${media.up('lg')}{
        flex: 1 0 calc(50% - 20px);
        max-width: calc(50% - 20px);
        h4{
            display: inline-block;
            font-size: 36px;
            line-height: 48px;
            margin: 0 0 54px;
        }
        p{
            font-size: 21px;
            line-height: 30px;
        }
        &:nth-child(2){
            h4{
                color: ${({theme}) => theme.colors.primaryOrange};
            }
        }
    }
`

export { Wrapper, LocationSelect, LocationList, SoloLocation };