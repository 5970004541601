import { Wrapper } from "./styles";

import { icons } from "./data";

const Socials = () => (
    <Wrapper>
        {icons.map(({id, icon, alt}) => (
            <a href="/#" key={id}>
                <img src={icon} alt={alt} />
            </a>
        ))}
    </Wrapper>
);

export { Socials };