import styled from "styled-components";
import { media } from "themes/media";
import LogoSmall from 'assets/images/billennium-logo-small.svg';
import LogoBig from 'assets/images/billennium-logo-big.svg';

const Wrapper = styled.div`
    width: 30px;
    height: 30px;
    background-image: url(${LogoSmall});
    background-size: 30px;
    a{
        display: block;
        width: 100%;
        height: 100%;
    }
    ${media.up('lg')} {
        width: 184px;
        height: 46px;
        background-image: url(${LogoBig});
        background-size: 184px 46px;
    }
`;

export { Wrapper };