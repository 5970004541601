import styled from 'styled-components';
import { media } from 'themes/media';

const Wrapper = styled.div`
    width: 100%;
    padding: 45px 30px;
    margin: 0 auto;
    box-sizing: border-box;
    ${media.up('lg')}{
        max-width: 1766px;
        padding: 200px 120px 45px;
    }
`;

export { Wrapper };