import { Slider } from "components/organisms/Slider";
import { SliderText } from "components/organisms/SliderText";
import { Locations } from "components/organisms/Locations";
import { Gallery } from "components/organisms/Gallery";
import { Partnerships } from "components/organisms/Partnerships";
import { SoloContent } from "components/organisms/SoloContent";
import { Parallax } from "components/organisms/Parallax";
import { Milestones } from "components/organisms/Milestones";

import good from "assets/icons/good.gif";
import earth from "assets/icons/earth.gif";
import shield from "assets/icons/shield.svg";
import iso from "assets/images/iso.png";
import bestWorkplaces from "assets/images/best-workplaces.png";

import { milestones, sliders, images, logos } from "./data";

const Home = () => (
    <>
        <Slider 
            sliders={sliders}
        />
        <SliderText 
            content={`Over 117 satisfied<span><img src="${good}"/></span>corporate, public and government clients.`} 
            staticText={false}    
        />
        <Locations 
            title="We are global IT services<br/> and solutions provider"
            content={`Our 1800+ IT experts work in a follow-the-sun <span>(24/7/365)</span> model to deliver the highest quality IT solutions and services for businesses around the globe.`}
        />
        <SliderText 
            content={`19 years in IT<span><img src="${earth}"/></span>world.`}
            staticText={true}
        />
        <Gallery 
            images={images}
            content="Established in 2003, Billennium has been in the IT services and solutions industry since then – we develop as the technology develops, delivering our clients the best-in-class solutions."
            link={`Read <a>about Billennium ↗</a>`}
        />
        <Partnerships 
            title="Driven by purpose, powered by technology partnerships"
            content="Official partnerships confirm our expertise in delivering<br /> tailor-made, multi-cloud, and cutting-edge technology<br /> IT solutions."
            content_contact="An official partnership means great commitment and benefits from the partner. It's also a validation of the skills and experience we offer to our clients. Partnership is also a better access to training and certifications, so our employees are constantly learning and improving their qualifications."
            logos={logos}
        />
        <SoloContent
            title={`Security<span><img src="${shield}"/></span>and stability of our clients' business is crucial`}
            content="We implemented several ISO standards to strengthen these<br/> vital values.<br/><br/>Read <a>about ISO standards ↗</a>"
            banner={iso}
            banner_desc="iso list"
            type="solo"
            top_break="52px"
        />
        <Parallax 
            title="BE A PART OF SOMETHING BIG"
            content="Apply to a legendary"
            link_text="Billennium Squad"
            link="/"
        />
        {milestones.length === 1 ?<SoloContent
            title={`Milestones<br/>& accomplishments`}
            content="13 na liście Najlepszych Miejsc Pracy Polska 2022 (firmy duże)<br/><br/>Read more about <a>Great Place To Work ↗</a>"
            banner={bestWorkplaces}
            banner_desc="Best workplaces 2022"
            type="solo"
            top_break="52px"
        />:
        <Milestones
            title="Milestones<br/> & accomplishments"
            milestones={milestones} 
        />
        }
    </>
);

export { Home };

