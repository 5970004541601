import { AnimationOnScroll } from 'react-animation-on-scroll';

import { Description } from "components/atoms/Description";
import { Wrapper, Top, Images, GallerySmall, GalleryBig } from "./style";

import { ReadLink } from 'components/atoms/ReadLink';
import { GalleryImages } from 'components/molecules/GalleryImages';
import { GalleryImagesLonger } from 'components/molecules/GalleryImagesLonger';

const Gallery = ({images, content, link}) => {
    return (
        <Wrapper>
            <AnimationOnScroll animateOnce={true} animateIn="animate__fadeIn" duration={1.5}>
                <Top>
                    <Description content={content} />
                </Top>
            </AnimationOnScroll>
            <AnimationOnScroll animateOnce={true} animateIn="animate__fadeIn" duration={1.5}>
                <Images>
                    <GallerySmall>
                        <GalleryImages />
                    </GallerySmall>
                    <GalleryBig>
                        <GalleryImagesLonger />
                    </GalleryBig>
                </Images>
                <ReadLink content={link} />
            </AnimationOnScroll>
        </Wrapper>
    )
};

export { Gallery };