import { useSwiper } from "swiper/react/swiper-react.js";
import { Wrapper, Button } from "./styles";
import SmallLeftArrow from 'assets/icons/arrow-left-small.svg';
import SmallRightArrow from 'assets/icons/arrow-right-small.svg';

const MilestonesButtons = () => {
    const swiper = useSwiper();  

    return (
        <Wrapper>
            <Button onClick={() => swiper.slidePrev()}><img src={SmallLeftArrow} alt="icon left arrow" /></Button>
            <Button onClick={() => swiper.slideNext()}><img src={SmallRightArrow} alt="icon right arrow" /></Button>
        </Wrapper>
    );
};

export { MilestonesButtons };