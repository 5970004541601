import {createGlobalStyle} from 'styled-components';
import {media} from "./media";
import {theme} from './theme';
import BillenniumRegular from 'assets/fonts/Billennium-Regular.woff';
import BillenniumSemiBold from 'assets/fonts/Billennium-SemiBold.woff';
import BillenniumExtraBold from 'assets/fonts/Billennium-ExtraBold.woff';
import BillenniumCompressedBold from 'assets/fonts/Billennium-CompressedBold.woff';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

//temporary font
const GlobalStyle = createGlobalStyle`@font-face {
  font-family: 'Billennium';
  font-weight: 400;
  src: url(${BillenniumRegular}) format('woff');
}

@font-face {
  font-weight: 600;
  font-family: 'Billennium';
  src: url(${BillenniumSemiBold}) format('woff');
}

@font-face {
  font-weight: 700;
  font-family: 'Billennium';
  src: url(${BillenniumExtraBold}) format('woff');
}

@font-face {
  font-weight: 900;
  font-family: 'Billennium';
  src: url(${BillenniumCompressedBold}) format('woff');
}

html {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  -ms-overflow-style: scrollbar;
}
 
*,
*::before,
*::after {
  -webkit-box-sizing: inherit;
          box-sizing: inherit;
}
body {
  font-family: ${theme.font};
  font-size: ${theme.fontSize};
  margin: 0;
  padding: 139px 0 0;
  line-height: normal;
  background: ${theme.colors.white};
  color: ${theme.colors.primaryGray};
  -webkit-font-smoothing: antialiased;
  ${media.down('lg')} {
    font-size: ${theme.mobile.fontSize};
  }
  ${media.up('lg')} {
    padding: 125px 0 0;
  }
  min-height: 100vh;
  overflow-anchor: none !important;
}

h1 {
  font-size: ${theme.desktop.h1.fontSize};
  font-weight: ${theme.desktop.h1.fontWeight};
  line-height: ${theme.desktop.h1.lineHeight};
  letter-spacing: ${theme.desktop.h1.letterSpacing};;
  color: ${theme.colors.black};
  ${media.down('lg')} {
    font-size: ${theme.mobile.h1.fontSize};
    font-weight: ${theme.mobile.h1.fontWeight};
    line-height: ${theme.mobile.h1.lineHeight};
    letter-spacing: ${theme.mobile.h1.letterSpacing};;
  }
}

h2 {
  font-size: ${theme.desktop.h2.fontSize};
  font-weight: ${theme.desktop.h2.fontWeight};
  line-height: ${theme.desktop.h2.lineHeight};
  letter-spacing: ${theme.desktop.h2.letterSpacing};;
  ${media.down('lg')} {
    font-size: ${theme.mobile.h2.fontSize};
    font-weight: ${theme.mobile.h2.fontWeight};
    line-height: ${theme.mobile.h2.lineHeight};
    letter-spacing: ${theme.mobile.h2.letterSpacing};;
  }
}

h3 {
  font-size: ${theme.desktop.h3.fontSize};
  font-weight: ${theme.desktop.h3.fontWeight};
  line-height: ${theme.desktop.h3.lineHeight};
  letter-spacing: ${theme.desktop.h3.letterSpacing};;
  color: ${theme.colors.black};
  ${media.down('lg')} {
    font-size: ${theme.mobile.h3.fontSize};
    font-weight: ${theme.mobile.h3.fontWeight};
    line-height: ${theme.mobile.h3.lineHeight};
    letter-spacing: ${theme.mobile.h3.letterSpacing};;
  }
}

input, textarea {
  font-family: ${theme.font};
  border: 1px solid #A4A6A0;
  border-radius: 6px;
  font-size: 15px;
  font-weight: 400;
  color: ${theme.colors.black};
  outline: none;
  resize: none;
  &:focus{
    color: ${theme.colors.black};
  }
}
`;

export default GlobalStyle;
