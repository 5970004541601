import styled from "styled-components";
import { media } from "themes/media";

import SmallArrow from "assets/icons/arrow-small.svg";

const Wrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 0 30px;
    ${media.up('lg')} {
        width: auto;
        padding: 0 0 0 40px;
        gap: 40px;
        height: 100%;
        align-items: center;
    }
    ${media.up('xl')} {
        padding: 0 8px 0 53px;
        gap: 53px;
    }
`;

const WrapperLang = styled.div`
    width: auto;
    position: relative;
    button{
        position: relative;
        padding-right: 55px;
        &:after{
            position: absolute;
            right: 34px;
            top: 21px;
            content: '';
            display: block;
            width: 6px;
            height: 3px;
            background-image: url(${SmallArrow});
        }
        ${media.up('lg')} {
            padding-right: 15px;
            &:after{
                top: 6px;
                right: 0;
            }
        }
    }
`

export { Wrapper, WrapperLang };