import styled from "styled-components";
import { media } from "themes/media";
import { IContent } from "./interfaces";

const Wrapper = styled.div`
    position: fixed;
    z-index: 1000;
    top: 0;
    display: flex;
    width: 100%;
    height: 30px;
    justify-content: space-between;
    align-items: center;
    background: ${({theme}) => theme.colors.white};
    padding: 67px 30px 42px;
    opacity: 1;
    transition: top 0.2s, opacity 0.4s;
    &.hide{
        top: -130px;
        opacity: 0;
        transition: top 0.2s, opacity 0.4s;
    }
    ${media.up('lg')} {
        height: 125px;
        padding: 0 40px;
        box-shadow: 0px 18px 49px rgba(0, 0, 0, 0.07), 0px 5.42647px 14.7721px rgba(0, 0, 0, 0.0456112), 0px 2.25388px 6.13556px rgba(0, 0, 0, 0.035), 0px 0.815184px 2.21911px rgba(0, 0, 0, 0.0243888);
    }
`;

const MobileHeader = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    ${media.up('lg')} {
        width: auto;
        button{
            display: none;
        }
    }
`

const Content = styled.div<IContent>`
    width: 100%;
    position: fixed;
    height: calc(100vh - 109px);
    background: ${({theme}) => theme.colors.white};
    top: 109px;
    left: 0;
    width: 100%;
    z-index: 1000;
    display: ${({show}) => show ? 'block' : 'none'}
    ${media.up('lg')} {
        width: auto;
        height: 100%;
        display: flex;
        align-items: center;
        position: static;
    }
`;

export { Wrapper, MobileHeader, Content };

