import styled from "styled-components";
import { media } from "themes/media";

const Wrapper = styled.div`
    position: fixed;
    height: 100vh;
    overflow: auto;
    width: 100%;
    box-shadow: 0px 18px 49px rgba(0, 0, 0, 0.07), 0px 5.42647px 14.7721px rgba(0, 0, 0, 0.0456112), 0px 2.25388px 6.13556px rgba(0, 0, 0, 0.035), 0px 0.815184px 2.21911px rgba(0, 0, 0, 0.0243888);
    background: ${({theme}) => theme.colors.white};
    border-radius: 5px;
    left: 0;
    top: 0;
    box-sizing: border-box;
    padding: 40px 40px 56px;
    z-index: 2000;
    &::-webkit-scrollbar {
        display: none;
    }
    ${media.up('lg')}{
        position: absolute;
        z-index: 5;
        height: auto;
    }
    ol{
        list-style: none;
        display: flex;
        flex-wrap: wrap;
        gap: 20px;
        padding: 0;
        margin: 0;
        li{
            flex: 1 0 100%;
            font-size: 36px;
            font-weight: 400;
            line-height: 48px;
            letter-spacing: -0.01em;
            text-align: left;
            padding: 0;
            margin: 0;
            span{
                color: ${({theme}) => theme.colors.black};
                text-decoration: none;
                cursor: pointer;
            }
            ul{
                border-top: 1px solid ${({theme}) => theme.colors.greyBorder};
                padding: 0 0 12px;
                margin: 12px 0 0;
                list-style: none;
                display: flex;
                flex-wrap: wrap;
                li{
                    font-size: 20px;
                    line-height: 50px;
                    letter-spacing: 0em;
                    text-align: left;
                    border-bottom: 1px solid ${({theme}) => theme.colors.greyBorder};
                    span{
                        color: ${({theme}) => theme.colors.black};
                        text-decoration: none;
                        cursor: pointer;
                        position: relative;
                        left: -20px;
                        &:before{
                            content: "→ ";
                            position: relative;
                            left: -20px;
                            opacity: 0;
                            color: ${({theme}) => theme.colors.primaryOrange};
                        }
                        &.active{
                            left: 0;
                            color: ${({theme}) => theme.colors.primaryOrange};
                            &:before{
                                opacity: 1;
                                left: 0;
                            }
                        }
                    }
                    &:hover{
                        span{
                            color: ${({theme}) => theme.colors.primaryOrange};
                            left: 0;
                            transition: color 0.3s, left 0.3s;
                            &:before{
                                left: 0;
                                opacity: 1;
                                transition: opacity 0.3s, left 0.3s;
                            }
                        }   
                    }
                }
            }
        }
    }
`;

export { Wrapper };