export const countries = [
    {
        id: 1,
        name: 'Poland',
    },
    {
        id: 2,
        name: 'Germany',
    },
    {
        id: 3,
        name: 'Malaysia',
    },
    {
        id: 4,
        name: 'India',
    },
    {
        id: 5,
        name: 'Canada',
    }
];