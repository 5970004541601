/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useSwiper } from "swiper/react/swiper-react.js";
import { Wrapper, Solo } from "./styles";

const NavigationSlider = ({sliders, activeIndex}) => {
    const [activeSlide, setActiveSlide] = useState(activeIndex);
    const [prevSlide, setPrevSlide] = useState(activeIndex);
    const [backSlide, setBackSlide] = useState(false);
    const swiper = useSwiper();

    const getClassName = (index) => {
        if(backSlide){
            if(activeSlide === index){
                return 'active-prev';
            }else if(activeSlide + 1 === index){
                return 'active-remove';
            }
        }

        if(activeSlide - prevSlide > 1){
            if(index === prevSlide){
                return 'prev-long';
            }else if(index === activeSlide){
                return 'active-wait';
            }
        } 

        if(activeSlide === index){
            return 'active';
        }else{
            return '';
        }
    }

    useEffect(() => {
        if(activeIndex === 0 && activeSlide === sliders.length - 1){
            setBackSlide(false);
        }else if(activeSlide > activeIndex){
            setBackSlide(true);
        }else{
            setBackSlide(false);
        }
        setPrevSlide(activeSlide);
        setActiveSlide(activeIndex);
    }, [activeIndex])

    return (
        <Wrapper>
            {sliders.map((slide, index) => (
                <Solo 
                    key={index} 
                    className={getClassName(index)}
                    onClick={() => swiper.slideTo(index)}
                >
                {(index + 1) < 10 ? '0' + (index + 1) : (index + 1)}.
                </Solo>
            ))}
        </Wrapper>
    )
};

export { NavigationSlider }; 