import { useState } from 'react';
import { AnimationOnScroll } from 'react-animation-on-scroll';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react.js';
import SwiperCore, { Autoplay, Navigation, EffectFade } from 'swiper';
import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'

import { Wrapper } from "./styles";
import { SoloSlider } from 'components/molecules/SoloSlider';
import { NavigationSlider } from 'components/molecules/NavigationSlider';

const Slider = ({sliders}) => {
    const [activeSliderId, changeSliderId] = useState(0)

    SwiperCore.use([Autoplay, Navigation, EffectFade]);

    return (
        <AnimationOnScroll animateOnce={true} animateIn="animate__fadeIn" duration={1.5}>
            <Wrapper>
                <Swiper
                effect='fade'
                spaceBetween={120}
                slidesPerView={1}
                onSlideChange={({activeIndex}) => changeSliderId(activeIndex)}
                autoplay={{ 
                    delay: 3000,
                    disableOnInteraction: false
                }}
                >
                {sliders.map(({id, title, content, image, video}) => (
                    <SwiperSlide key={id}>
                        <SoloSlider 
                            title={title}
                            content={content}
                            banner={image}
                            video={video}
                        />
                    </SwiperSlide>
                ))}
                <NavigationSlider sliders={sliders} activeIndex={activeSliderId} />
                </Swiper>
            </Wrapper>
        </AnimationOnScroll>
    )
};

export { Slider };