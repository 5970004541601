export const locations = [
    {
        id: 1,
        name: 'Poland',
        cities: [
            {
                id: 1,
                name: 'Warsaw HQ',
            },
            {
                id: 2,
                name: 'Lublin',
            },
            {
                id: 3,
                name: 'Olsztyn',
            },
            {
                id: 4,
                name: 'Łódź',
            },
            {
                id: 5,
                name: 'Bytom',
            },
            {
                id: 6,
                name: 'Starogard Gdański',
            },
            {
                id: 7,
                name: 'Białystok',
            } 
        ]
    },
    {
        id: 2,
        name: 'Germany',
        cities: [],
    },
    {
        id: 3,
        name: 'Malaysia',
        cities: [],
    },
    {
        id: 4,
        name: 'Canada',
        cities: [],
    },
    {
        id: 5,
        name: 'India',
        cities: [],
    }
];