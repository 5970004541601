import styled from "styled-components";
import { media } from "themes/media";

const Wrapper = styled.div`
    min-width: 100%;
    box-shadow: 0px 18px 49px rgb(0 0 0 / 7%), 0px 5.42647px 14.7721px rgb(0 0 0 / 5%), 0px 2.25388px 6.13556px rgb(0 0 0 / 4%), 0px 0.815184px 2.21911px rgb(0 0 0 / 2%);
    border-radius: 5px;
    position: absolute;
    bottom: 0;
    left: 0;
    background: #ffffff;
    padding: 15px 34px;
    ul{
        list-style: none;
        padding: 0;
        display: flex;
        flex-wrap: wrap;
        gap: 15px;
        margin: 0;
        li{
            flex: 1 0 100%;
            font-size: 18px;
            line-height: 21px;
            font-weight: 400;
            color: ${({theme}) => theme.colors.primaryGray};
            cursor: pointer;
            &.active{
                color: ${({theme}) => theme.colors.black};
            }
        }
    }
    ${media.up('lg')}{
        padding: 15px;
        left: -15px;
        top: 0;
        bottom: auto;
        ul li{
            font-size: 16px;
            line-height: 18px;
        }
    }
    ${media.up('xl')}{
        ul li{
            font-size: 20px;
            line-height: 23px;
        }
    }
`;

export { Wrapper };