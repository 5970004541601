import styled from "styled-components";

const Wrapper = styled.div`
    width: 100%;
    box-sizing: border-box;
`;

const Top = styled.div`
    width: 100%;
    padding-bottom: 100px;
    position: relative;
    margin: 0 0 86px;
    //border-bottom: 1px solid ${({theme}) => theme.colors.borderOtherGray};
    min-height: 201px;
    display: flex;
    align-items: center;
    img{
        max-height: 100px;
        max-width: 94px;
    }
    &::before,&::after{
        position: absolute;
        content: '';
        display: block;
    }
    &::before{
        left: 0;
        width: 12px;
        height: 12px;
        bottom: -6px;
        background: ${({theme}) => theme.colors.primaryOrange};
        border-radius: 12px;
    }
    &::after{
      background: ${({theme}) => theme.colors.borderOtherGray};
      right:-30%;
      left:-30%;
      bottom:0;
      height:1px;
      z-index: -1;
    }
`;

const Content = styled.div`
    width: 100%;
    box-sizing: border-box;
    h6{
        font-size: 36px;
        font-weight: 400;
        line-height: 56px;
        letter-spacing: 0em;
        text-align: left;
        padding: 0;
        margin: 0 0 36px;
        color: ${({theme}) => theme.colors.black};
    }
    p{
        font-size: 16px;
        font-weight: 400;
        line-height: 25px;
        letter-spacing: 0em;
        text-align: left;
        padding: 0;
        margin: 0;
        color: ${({theme}) => theme.colors.black};
        max-width: 290px;
    }
`;

export {Wrapper, Top, Content};