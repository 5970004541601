import styled from 'styled-components';
import { media } from 'themes/media';

const Wrapper = styled.p`
    display: none;
    text-align: center;
    font-size: 20px;
    font-weight: 400;
    line-height: 29px;
    letter-spacing: 0em;
    color: ${({theme}) => theme.colors.black};
    a{
        position: relative;
        display: inline-block;
        height: 16px;
        top: -5px;
        font-size: 13px;
        font-weight: 400;
        line-height: 29px;
        letter-spacing: 0em;
        text-align: left;
        color: ${({theme}) => theme.colors.primaryOrange};
        cursor: pointer;
        margin: 0;
        padding: 0;
        border-bottom: 1px solid ${({theme}) => theme.colors.primaryOrange};
    }
    ${media.up('lg')}{
        display: inline-block;
        font-size: 36px;
        line-height: 45px;
        a{
            height: 38px;
            top: -8px;
            font-size: 20px;
            line-height: 45px;
        }
    }
`;

export { Wrapper };