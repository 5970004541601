import styled from "styled-components";
import { media } from "themes/media";

import { IWrapper } from "./interfaces";

const Wrapper = styled.div<IWrapper>`
    div{
        overflow: hidden !important;
    }
    ${media.up('xxl')} {
        display: ${({staticText}) => staticText ? 'none' : 'block'};
    }
`

const Static = styled.div`
    display: none;
    width: 100%;
    text-align: center;
    p{
        padding: 0;
        span{
            text-align: left;
        }
    }
    ${media.up('xxl')} {
        display: block;
    }
`

const Content = styled.p<IWrapper>`
    font-size: 72px;
    font-weight: 400;
    line-height: 101px;
    letter-spacing: -0.01em;
    color: ${({theme}) => theme.colors.black};
    margin: 113px 0 0;
    padding: 0;
    span{
        display: inline-block;
        height: 59px;
        width: 74px;
        position: relative;
        margin: 0;
        padding: 0;
        img{
            position: absolute;
            height: 74px;
            width: 74px;
            margin: 0;
            padding: 0;
        }
    }
    ${media.up('lg')} {
        font-size: 160px;
        line-height: 224px;
        margin: 200px 0 0;
        padding-left: 200px;
        span{
            height: 164px;
            width: 236px;
            img{
                width: 236px;
                height: 236px;
            }
        }
    }
    ${media.up('xxl')} {
        margin: ${({staticText}) => staticText ? '200px auto 0' : '200px 0 0'};
    }
`;

export { Content, Wrapper, Static };