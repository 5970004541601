import { Wrapper, List } from "./styles";

import { countries } from "./data";

const LocationsTabs = ({activeLocation, handleClick}) => (
    <Wrapper>
        <p>Out worldwide locations:</p>
        <List>
            {countries.map(({id, name}, index) => (
                <span 
                key={id} 
                className={name === activeLocation ? 'active' : ''}
                onClick={() => handleClick(name)}
                >{name}</span>
            ))}
        </List>
    </Wrapper>
);

export { LocationsTabs };