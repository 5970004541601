import { FunctionComponent } from "react";
import { Wrapper } from "./styles";
import { TextareaInterface } from "./interface";

const Textarea: FunctionComponent<TextareaInterface> = ({name, value, placeholder, error, handleChange}) => (
    <Wrapper error={error}>
        <textarea placeholder={placeholder} name={name} value={value} onInput={(e) => handleChange(e)}></textarea>
    </Wrapper>
);

export { Textarea };