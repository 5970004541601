import { AnimationOnScroll } from 'react-animation-on-scroll';
import { Wrapper } from "./styles";

import { SoloSlider } from "components/molecules/SoloSlider";

const SoloContent = ({title, content, banner, banner_desc, type, top_break}) => (
    <Wrapper>
        <AnimationOnScroll animateOnce={true} animateIn="animate__fadeInUp" duration={1.5}>
            <SoloSlider
                title={title}
                content={content}
                banner={banner}
                banner_desc={banner_desc}
                type={type}
                top_break={top_break}
            />
        </AnimationOnScroll>
    </Wrapper>
);

export { SoloContent };