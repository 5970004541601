import { Switch, Route } from "react-router-dom";
 
import { Home } from "views/Home";
import { ErrorPage } from "views/ErrorPage";

const AppRoutes = () => (
    <Switch>
        <Route key='Home' exact path='/' component={Home}/>
        <Route key='Home' path='*' component={ErrorPage}/>
    </Switch>
);

export default AppRoutes;