import { useState } from "react";
import { useEffect, useRef } from 'react';
import { withRouter } from 'react-router-dom';
import useScrollPosition from '@react-hook/window-scroll'

import { Wrapper, MobileHeader, Content } from "./style";

import { Logo } from "components/atoms/Logo";
import { NavigationLinks } from "components/molecules/NavigationLinks";
import { NavigationButtons } from "components/molecules/NavigationButtons";
import { ButtonIcon } from "components/atoms/ButtonIcon";

import Close from 'assets/icons/close.svg';
import Burger from 'assets/icons/burger.svg';

const NavigationComponent = ({history}) => {
    const scrollY = useScrollPosition(60);
    const prevScrollY = useRef(scrollY);
    const [showNav, setShowNav] = useState(true);
    const [isOpen, updateOpen] = useState(false);
    const ref: any = useRef(history.location);

    useEffect(() => {
        const listen = history.listen(() => {
            if (!ref.current) return null;
            if (ref.current.pathname !== history.location.pathname) {
                updateOpen(false)
            }
            ref.current = history.location;
        });
        return () => {
            listen();
        };
    }, [history]);

    useEffect(() => {
        if (scrollY < prevScrollY.current || scrollY < 250){
            setShowNav(true);
        }else{
            setShowNav(false);
        }
        prevScrollY.current = scrollY;
    }, [scrollY]);

    return (
        <Wrapper className={!showNav && !isOpen ? 'hide' : ''}>
            <MobileHeader>
                <Logo />
                <ButtonIcon icon={isOpen ? Close : Burger} info="burger" handleClick={() => updateOpen(!isOpen)}/>
            </MobileHeader>
            <Content show={isOpen}>
                <NavigationLinks />
                <NavigationButtons />
            </Content>
        </Wrapper>
    );
};

export const Navigation = withRouter(NavigationComponent);