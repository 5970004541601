import styled from 'styled-components';
import { media } from 'themes/media';

const Wrapper = styled.div`
    width: 100%;
    display: none;
    gap: 20px;
    justify-content: space-between;
    align-items: start;
    ${media.up('lg')}{
        display: flex;
    }
`;

const Column = styled.div`
    flex: 1 0 calc(25% - 20px);
    max-width: calc(25% - 20px);
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
`;

const Row = styled.div`
    flex: 1 0 100%;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
`;

const RowHeader = styled.div`
    flex: 1 0 100%;
    min-width: 100%;
    min-height: 40px;
    h5{
        font-size: 20px;
        font-weight: 400;
        line-height: 40px;
        letter-spacing: -0.01em;
        text-align: left;
        margin: 0;
        a{
            color: ${({theme}) => theme.colors.black};
            text-decoration: none;
        }
    }
    &.border{
        h5{
            padding-top: 20px;
            border-top: 1px solid ${({theme}) => theme.colors.borderColorMain};
        }
    }
`;

const RowContent = styled.div`
    flex: 1 0 100%;
    min-width: 100%;
    display: flex;
    gap: 46px;
`;

const List = styled.div`
    width: 100%;
    display: flex;
    gap: 25px;
    flex-wrap: wrap;
    h6{
        flex: 1 0 100%;
        min-height: 40px;
        font-size: 16px;
        font-weight: 400;
        line-height: 40px;
        letter-spacing: -0.01em;
        text-align: left;
        color: ${({theme}) => theme.colors.primaryOrange};
        margin: 0;
        padding: 0;
        border-bottom: 1px solid ${({theme}) => theme.colors.borderColorMain};
    }
    ul{
        flex: 1 0 100%;
        list-style: none;
        margin: 0;
        padding: 0;
        li{
            font-size: 20px;
            font-weight: 400;
            line-height: 34px;
            letter-spacing: 0em;
            text-align: left;
            margin: 0;
            padding: 0;
            a{
                color: ${({theme}) => theme.colors.linkGray};
                text-decoration: none;
            }
        }
    }
`;

export { Wrapper, Column, Row, RowHeader, RowContent, List };

