import styled from "styled-components";
import { media } from "themes/media";

const Wrapper = styled.div`
    width: 100%;
    display: flex;
    gap: 13px;
    align-items: stretch;
    ${media.up('lg')}{
        order: 2;
        gap: 20px;
    }
`;

const Avatar = styled.div`
    width: 47px;
    height: 47px;
    border-radius: 47px;
    overflow: hidden;
    img{
        width: 100%;
    }
    ${media.up('lg')}{
        width: 70px;
        height: 70px;
        border-radius: 70px;
    }
`;

const Content = styled.div`
    display: flex;
    justify-content: start;
    align-items: center;
    p{
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0em;
        text-align: left;
        margin: 0;
        padding: 0;
        color: ${({theme}) => theme.colors.black};
        span{
            color: #CFCFCF;
        }
        ${media.up('lg')}{
            font-size: 21px;
            line-height: 29px;
        }
    }
`;

export { Wrapper, Avatar, Content };