import { Outline, Wrapper, List } from "./styles";

import map from 'assets/images/map.svg';

const LocationsMap = ({activeLocation, handleClick}) => (
    <Outline>
        <Wrapper>
            <span id="canada" className={activeLocation === 'Canada' ? 'active' : ''}>
                <button onClick={() => handleClick('Canada')}></button>
            </span>
            <span id="germany" className={activeLocation === 'Germany' ? 'active' : ''}>
                <button onClick={() => handleClick('Germany')}></button>
            </span>
            <span id="poland" className={activeLocation === 'Poland' ? 'active' : ''}>
                <button onClick={() => handleClick('Poland')}></button>
                <List>
                    <ul>
                        <li className="active">Warsaw HQ</li>
                        <li>Lublin</li>
                        <li>Olsztyn</li>
                        <li>Łódź</li>
                        <li>Bytom</li>
                        <li>Starogard Gdański</li>
                        <li>Białystok</li>
                    </ul>
                </List>
            </span>
            <span id="india" className={activeLocation === 'India' ? 'active' : ''}>
                <button onClick={() => handleClick('India')}></button>
            </span>
            <span id="malaysia" className={activeLocation === 'Malaysia' ? 'active' : ''}>
                <button onClick={() => handleClick('Malaysia')}></button>
            </span>
            <img src={map} alt="Billennium locations" />
        </Wrapper>
    </Outline>
);

export { LocationsMap };