import ReactHtmlParser from 'react-html-parser';
import {useEffect, useRef, useState} from 'react';
import {AnimationOnScroll} from 'react-animation-on-scroll';
import {Swiper, SwiperSlide} from 'swiper/react/swiper-react.js';
import SwiperCore, {Navigation} from 'swiper';
import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'

import {Outline, Wrapper, Top, Content} from "./styles";

import {MilestonesButtons} from "components/molecules/MilestonesButtons";
import {SoloMilestone} from 'components/molecules/SoloMilestone';

const Milestones = ({milestones, title}) => {
    // const [activeSlide, setActiveSlide] = useState(-1);
    SwiperCore.use([Navigation]);

    const [slides, setSlides] = useState<HTMLElement[]>([]);

    useEffect(() => {
        if (slides) {
            slides.forEach((s: any, idx: number) => {
                s.style.transitionDelay = (idx * 50) + 'ms';
            });
        }
        //     if (activeSlide > 0) {
        //         const slides = Array.from(
        //             document.getElementsByClassName('swiper-slide')
        //         );
        //         slides.forEach((element, index) => {
        //             const slide = element as HTMLElement | null;
        //             if (slide != null) {
        //                 // const width = slide.offsetWidth;
        //                 // let extraWidth = 0;
        //                 // if(slide.classList.contains('swiper-slide-prev')){
        //                 //     extraWidth = 30;
        //                 // }else if('swiper-slide-next'){
        //                 //     extraWidth = 20;
        //                 // }else if(index === activeSlide + 1){
        //                 //     extraWidth = 10;
        //                 // }
        //                 // element.setAttribute('style',`width: ${width + extraWidth}px;`);
        //                 // setTimeout(() => {
        //                 //     element.setAttribute('style',`width: ${width}px;`);
        //                 // }, 500)
        //             }
        //         })
        //     }
    }, [slides])

    return (
        <Outline>
            <AnimationOnScroll animateOnce={true} animateIn="animate__fadeIn" duration={1.5}>
                <Wrapper>
                    <Top>
                        <h5>{ReactHtmlParser(title)}</h5>
                    </Top>
                    <Content id="milestones">
                        <Swiper
                            onInit={s => {
                                const slidesList: HTMLElement[] = [];
                                s.slides.forEach(s => slidesList.push(s as HTMLElement));
                                setSlides(slidesList);
                            }}
                            onTransitionStart={s => {
                                slides.forEach((slide) => {
                                    slide.style.transform = `translate3d(${s.translate}px,0,0)`;
                                })
                            }}
                            speed={1100}
                            followFinger={false}
                            noSwiping={true}
                            simulateTouch={false}
                            // longSwipesMs={0}
                            breakpoints={{
                                576: {
                                    slidesPerView: 2,
                                },
                                768: {
                                    slidesPerView: 3,
                                },
                                1200: {
                                    slidesPerView: 4,
                                }
                            }}
                            spaceBetween={0}
                            slidesPerView={1}
                            // onSlideChange={({activeIndex}) => setActiveSlide(activeIndex)}
                        >
                            {milestones.map(({title, content, image, year}, index) => (
                                <SwiperSlide key={index}>
                                    <SoloMilestone
                                        title={title}
                                        year={year}
                                        content={content}
                                        image={image}
                                    />
                                </SwiperSlide>
                            ))}
                            <MilestonesButtons/>
                        </Swiper>
                    </Content>
                </Wrapper>
            </AnimationOnScroll>
        </Outline>
    );
};

export {Milestones};