import { BrowserRouter as Router } from 'react-router-dom';

import GlobalStyle from './GlobalStyles';
import AppRoutes from 'Routes';
import ScrollToTop from './scroll-up-routes';

import { ThemeProvider } from "styled-components";
import { theme } from './theme';
import { ModalContact } from 'components/organisms/ModalContact';
import { Navigation } from 'components/organisms/Navigation';
import { Footer } from 'components/organisms/Footer';

const Layout = () => (
    <ThemeProvider theme={theme}>
        <GlobalStyle />
        <Router>
            <ScrollToTop />
            <ModalContact />
            <Navigation />
            <AppRoutes />
            <Footer />
        </Router>
    </ThemeProvider>
);

export { Layout };