import styled from "styled-components";
import { media } from "themes/media";

const Wrapper = styled.ul`
    list-style: none;
    margin: 0;
    padding: 32px 30px 70px;
    li{
        a{
            font-size: 28px;
            font-weight: 400;
            line-height: 40px;
            letter-spacing: -0.01em;
            color: ${({theme}) => theme.colors.black};
            text-decoration: none;
        }
    }
    ${media.up('lg')} {
        padding: 0;
        display: flex;
        height: 46px;
        gap: 40px;
        padding-right: 40px;
        border-right: 1px solid ${({theme}) => theme.colors.lightGray};
        li{
            padding: 0;
            margin: 0;
            a{
                font-size: 16px;
                line-height: 46px;
                letter-spacing: 0em;
                text-align: left;
            }
        }
    }
    ${media.up('xl')} {
        gap: 53px;
        padding-right: 53px;
        li{
            a{
                font-size: 21px;
            }
        }
    }
`;

export { Wrapper };