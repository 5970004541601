import styled from "styled-components";
import { media } from "themes/media";

const Outline = styled.div`
    width: 100%;
    overflow: hidden;
    margin: 46px 0 90px;
    padding-bottom: 0pt;
    padding: 0 0 40px;
    ${media.up('lg')} {
        padding: 0 30px;
        max-width: 1452px;
        margin: 200px auto;
    }
`;

const Wrapper = styled.div`
    width: 550px;
    position: relative;
    box-sizing: border-box;
    margin-left: -85px;
    padding: 0;
    img{
        width: 100%;
    }
    span{
        width: 14px;
        height: 14px;
        display: flex;
        position: absolute;
        background-color: rgba(255, 90, 0, 0);
        border-radius: 100%;
        justify-content: center;
        align-items: center;
        transition: background-color 0.3s, width 0.3s, height 0.3s;
        ${media.up('lg')} {
            width: 36px;
            height: 36px;
        }
        button{
            width: 4px;
            height: 4px;
            border-radius: 100%;
            padding: 0;
            border: none;
            background-color: ${({theme}) => theme.colors.primaryOrange};
            cursor: pointer;
            ${media.up('lg')} {
                width: 8px;
                height: 8px;
            }
        }
        &.active{
            background-color: rgba(255, 90, 0, 0.2);
            transition: background-color 0.3s;
            button{
                width: 8px;
                height: 8px;
                animation: pulsMobileSlow 1s infinite;
                animation-direction: alternate;
                ${media.up('lg')} {
                    width: 20px;
                    height: 20px;
                    animation: pulsDesktopSlow 1s infinite;
                    animation-direction: alternate;
                }
                transition: width 0.3s, height 0.3s;
            }
            &:hover{
                button{
                    ${media.up('lg')} {
                        width: 36px;
                        height: 36px;
                    }
                }
            }
            div{
                opacity: 1;
                top: 5px;
                ${media.up('lg')} {
                    top: 15px;
                }
            }
        }
        &:hover{
            background-color: rgba(255, 90, 0, 0.2);
            transition: background-color 0.3s;
            button{
                width: 8px;
                height: 8px;
                animation: pulsMobile 0.5s infinite;
                animation-direction: alternate;
                ${media.up('lg')} {
                    width: 20px;
                    height: 20px;
                    animation: pulsDesktop 0.5s infinite;
                    animation-direction: alternate;
                }
                transition: width 0.3s, height 0.3s;
            }
        }
        &#canada{
            top: 56px;
            left: 144px;
            ${media.up('sm')} {
                top: 50px;
                left: 130px;
            }
            ${media.up('md')} {
                top: 76px;
                left: 187px;
            }
            ${media.up('lg')} {
                top: 115px;
                left: 283px;
            }
            ${media.up('xl')} {
                top: 145px;
                left: 358px;
            }
        }
        &#germany{
            top: 51px;
            left: 264px;
            ${media.up('sm')} {
                top: 45px;
                left: 240px;
            }
            ${media.up('md')} {
                top: 67px;
                left: 338px;
            }
            ${media.up('lg')} {
                top: 97px;
                left: 526px;
            }
            ${media.up('xl')} {
                top: 125px;
                left: 658px;
            }
        }
        &#poland{
            top: 48px;
            left: 295px;
            ${media.up('sm')} {
                top: 42px;
                left: 268px;
            }
            ${media.up('md')} {
                top: 61px;
                left: 378px;
            }
            ${media.up('lg')} {
                top: 90px;
                left: 585px;
            }
            ${media.up('xl')} {
                top: 120px;
                left: 733px;
            }
        }
        &#india{
            top: 100px;
            left: 356px;
            ${media.up('sm')} {
                top: 90px;
                left: 322px;
            }
            ${media.up('md')} {
                top: 128px;
                left: 457px;
            }
            ${media.up('lg')} {
                top: 197px;
                left: 710px;
            }
            ${media.up('xl')} {
                top: 250px;
                left: 888px;
            }
        }
        &#malaysia{
            top: 129px;
            left: 399px;
            ${media.up('sm')} {
                top: 118px;
                left: 363px;
            }
            ${media.up('md')} {
                top: 167px;
                left: 511px;
            }
            ${media.up('lg')} {
                top: 256px;
                left: 797px;
            }
            ${media.up('xl')} {
                top: 324px;
                left: 997px;
            }
        }
    }
    ${media.up('sm')} {
        width: 500px;
        margin: 46px auto 90px;
    }
    ${media.up('md')} {
        width: 700px;
    }
    ${media.up('lg')} {
        width: 1100px;
        margin: 0 auto;
    }
    ${media.up('xl')} {
        width: 1370px;
    }
    @keyframes pulsMobile {
        from {
            width: 4px;
            height: 4px;
        }
        to{
            width: 10px;
            height: 10px;
        }
    }
    @keyframes pulsDesktop {
        from {
            width: 10px;
            height: 10px;
        }
        to{
            width: 24px;
            height: 24px;
        }
    }
    @keyframes pulsMobileSlow {
        from {
            width: 10px;
            height: 10px;
        }
        to{
            width: 6px;
            height: 6px;
        }
    }
    @keyframes pulsDesktopSlow {
        from {
            width: 14px;
            height: 14px;
        }
        to{
            width: 24px;
            height: 24px;
        }
    }
`;

const List = styled.div`
    background: ${({theme}) => theme.colors.white};
    border-radius: 5px;
    padding: 30px 46px 30px 34px;
    box-sizing: border-box;
    z-index: 5;
    box-shadow: 0px 18px 49px rgba(0, 0, 0, 0.07), 0px 5.42647px 14.7721px rgba(0, 0, 0, 0.0456112), 0px 2.25388px 6.13556px rgba(0, 0, 0, 0.035), 0px 0.815184px 2.21911px rgba(0, 0, 0, 0.0243888);
    opacity: 0;
    top: 200px;
    transition: top 0.5s, opacity 0.5s;
    position: absolute;
    right: 5px;
    z-index: 5;
    ${media.up('lg')}{
        right: auto;
        left: 15px;
    }
    ul{
        list-style: none;
        margin: 0;
        padding: 0;
        li{
            min-width: 120px;
            font-size: 14px;
            font-weight: 400;
            line-height: 24px;
            letter-spacing: 0em;
            text-align: left;
            color: ${({theme}) => theme.colors.black};
            margin: 0;
            padding: 0;
            &.active{
                color: ${({theme}) => theme.colors.primaryOrange};
            }
        }
    }
`

export { Outline, Wrapper, List };