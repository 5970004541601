import { Link } from "react-router-dom";
import { Wrapper, Info, List } from "./styles";

import { Socials } from "components/molecules/Socials";

import { links } from "./data";

const FooterBottom = () => (
    <Wrapper>
        <Info>
            <Socials />
            <p>© Billennium <span>2003-2022</span></p>
        </Info>
        <List>
            <ul>
                {links.map(({id, title, link, out}) => (
                    <li key={id}>
                        {out ?
                            <a href={link}>{title}</a>
                        :
                            <Link to={link}>{title}</Link>
                        }
                    </li>
                ))}
            </ul>
        </List>
    </Wrapper>
);

export { FooterBottom }