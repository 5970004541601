import styled from "styled-components";
import { media } from "themes/media";

const Wrapper = styled.div`
    width: 100%;
    box-sizing: border-box;
    margin: 81px 0 0;
    display: flex;
    flex-wrap: wrap;
    gap: 36px;
    ${media.up('lg')}{
        margin: 132px 0 0;
        justify-content: space-between;
    }
`;

const Info = styled.div`
    flex: 1 0 100%;
    display: flex;
    justify-content: space-between;
    p{
        font-size: 14px;
        font-weight: 400;
        line-height: 56px;
        letter-spacing: -0.01em;
        text-align: left;
        color: ${({theme}) => theme.colors.black};
        margin: 0;
        padding: 0;
        span{
            display: none;
        }
    }
    ${media.up('lg')}{
        flex: 0 0 auto;
        order: 2;
        div{
            display: none;
        }
        p{
            font-size: 20px;
            line-height: 28px;
            span{
                display: inline-block;
            }
        }
    }
`;

const List = styled.div`
    flex: 1 0 100%;
    ul{
        list-style: none;
        display: flex;
        justify-content: space-between;
        padding: 0;
        margin: 0;
        li{
            font-size: 14px;
            font-weight: 400;
            line-height: 28px;
            letter-spacing: -0.01em;
            text-align: left;
            a{
                text-decoration: none;
                color: ${({theme}) => theme.colors.black};
            }
        }
    }
    ${media.up('lg')}{
        flex: 0 0 auto;
        ul{
            gap: 69px;
            li{
                font-size: 20px;
                line-height: 28px;
            }
        }
    }
`;

export { Wrapper, Info, List }