import styled from "styled-components";
import { media } from "themes/media";

import { IDescription } from "./interfaces";

const Wrapper = styled.p<IDescription>`
    width: 100%;
    padding: 0 30px;
    font-size: ${({type}) => type === 'smaller' ? 14 : 18}px;
    font-weight: 400;
    line-height: ${({type}) => type === 'smaller' ? 19 : 25}px;
    letter-spacing: 0em;
    text-align: center;
    margin: 0 auto;
    color: ${({theme}) => theme.colors.black};
    span{
        display: inline-block;
        color: ${({theme}) => theme.colors.primaryOrange};
    }
    ${media.up('lg')} {
        max-width: ${({type}) => type === 'smaller' ? 904 : 1116}px;
        margin: 0 auto;
        font-size: ${({type}) => type === 'smaller' ? 21 : 36}px;
        line-height: ${({type}) => type === 'smaller' ? 30 : 56}px;
    }
`;

export { Wrapper };