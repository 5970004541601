import Marquee from "react-fast-marquee";
import ReactHtmlParser from 'react-html-parser';
import { AnimationOnScroll } from 'react-animation-on-scroll';
import { Content, Wrapper, Static } from "./styles";

const SliderText = ({content, staticText}) => (
    <AnimationOnScroll animateOnce={true} animateIn="animate__fadeIn" duration={1.5}>
        <Wrapper staticText={staticText}>
            <Marquee gradientWidth={0} speed={200}>
                <Content staticText={staticText}>{content && ReactHtmlParser(content)}&nbsp;&nbsp;</Content>
            </Marquee>
        </Wrapper>
        {staticText &&<Static>
            <Content staticText={staticText}>{content && ReactHtmlParser(content)}&nbsp;&nbsp;</Content>
        </Static>}
    </AnimationOnScroll>
);

export { SliderText };