import { Wrapper } from "./styles";
import { useTranslation } from "react-i18next";

import { connect } from "react-redux";
import { toggleContactForm } from "actions";

const ButtonContactComponent = ({toggleContactForm}) => {
     const { t } = useTranslation();

    return (
        <Wrapper onClick={() => toggleContactForm(true)}>
            { t('Contact us') }
        </Wrapper>
    )
};

const mapDispatchToProps = (dispatch) => ({
    toggleContactForm: () => dispatch(toggleContactForm(true)),
})

export const ButtonContact = connect(null, mapDispatchToProps)(ButtonContactComponent);