import { useState } from "react";
import { Wrapper, LocationSelect, LocationList, SoloLocation } from "./styles";

import { Description } from "components/atoms/Description";
import { LocationSelect as Dropdown } from "components/molecules/LocationsSelect";
import { Title } from "components/atoms/Title";

const FooterCountries = () => {
    const [location, setLocation] = useState({
        country: 'Poland',
        city: 'Warsaw HQ'
    })

    return (
        <Wrapper>
            <LocationSelect>
                <Dropdown setLocation={(data) => setLocation(data)}/>
            </LocationSelect>
            <LocationList>
                <SoloLocation>
                    <Title content={location.country} />
                    <Description
                        content={`Koszykowa 61<br/>00-667 Warszawa`}
                    />
                </SoloLocation>
                <SoloLocation>
                    <Title content={location.city} />
                    <Description
                        content={`+48 22 844 15 00<br/>recepcja.warszawa@billennium.com`}
                    />
                </SoloLocation>
            </LocationList>
        </Wrapper>
    );
};

export { FooterCountries };