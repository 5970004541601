import { useState } from "react";
import { AnimationOnScroll } from 'react-animation-on-scroll';
import { Wrapper } from "./styles";

import { Description } from "components/atoms/Description";
import { LocationsContent } from "components/molecules/LocationsContent";
import { LocationsMap } from "components/molecules/LocationsMap";
import { LocationsTabs } from "components/molecules/LocationsTabs";

const Locations = ({title, content}) => {
    const [activeLocation, setActiveLocation] = useState('Poland');

    return (
        <Wrapper>
            <AnimationOnScroll animateOnce={true} animateIn="animate__fadeInUp" duration={1.5}>
                <LocationsContent 
                    title={title}
                />
            </AnimationOnScroll>
            <AnimationOnScroll animateOnce={true} animateIn="animate__fadeIn" duration={1.5}>
                <LocationsTabs 
                    activeLocation={activeLocation}
                    handleClick={(country) => setActiveLocation(country)}
                />
            </AnimationOnScroll>
            <AnimationOnScroll animateOnce={true} animateIn="animate__fadeIn" duration={2}>
                <LocationsMap
                    activeLocation={activeLocation}
                    handleClick={(country) => setActiveLocation(country)}
                />
            </AnimationOnScroll>
            <AnimationOnScroll animateOnce={true} animateIn="animate__fadeIn" duration={1.5}>
                <Description 
                    content={content}
                />
            </AnimationOnScroll>
        </Wrapper>
    );
};

export { Locations };