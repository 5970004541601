import { FunctionComponent } from "react";
import { Wrapper } from "./styles";
import { InputInterface } from "./interface";

const Input: FunctionComponent<InputInterface> = ({name, value, placeholder, type, error, handleChange}) => (
    <Wrapper error={error}>
        <input type={type} placeholder={placeholder} name={name} value={value} onInput={(e) => handleChange(e)}/>
    </Wrapper>
);

export { Input };