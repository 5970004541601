import { FunctionComponent } from "react";
import ReactHtmlParser from 'react-html-parser';
import { ISoloSlider } from "./interfaces";
import { Wrapper, Content, Video } from "./styles";

import { SmallBanner } from "components/atoms/SmallBanner";

const SoloSlider:FunctionComponent<ISoloSlider> = ({title, content, banner, banner_desc, video, type, top_break}) => (
    <Wrapper type_slide={type} top_break={top_break}>
        <Content>
            {title &&<h3>{ReactHtmlParser(title)}</h3>}
            {content &&<p>{ReactHtmlParser(content)}</p>}
        </Content>
        {video && <Video autoPlay playsInline loop muted poster={banner}>
            <source src={video} type="video/mp4" />
        </Video>}
        {banner && !type && !video &&<SmallBanner banner={banner}/>}
        {banner && !video && type === 'solo' &&<img alt={banner_desc} src={banner}/>}
    </Wrapper>
);

export { SoloSlider };