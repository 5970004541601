import { ContactForm } from 'components/organisms/ContactForm';

import { connect } from "react-redux";
import { toggleContactForm } from "actions";

const ModalContactComponent = ({isContactFormShow, toggleContactForm}) => {
    const closeForm = () => toggleContactForm(false);

    return  (
        <>
            {isContactFormShow&&<ContactForm
                closeModal={closeForm}
                handleClose={(data) => toggleContactForm(data)}
            />}
        </>
    )
}

const mapStateToProps = ({isContactFormShow}) => ({isContactFormShow});

const mapDispatchToProps = (dispatch) => ({
    toggleContactForm: (isContactFormShow) => dispatch(toggleContactForm(isContactFormShow)),
})

export const ModalContact = connect(mapStateToProps, mapDispatchToProps)(ModalContactComponent);