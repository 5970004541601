import { Link } from 'react-router-dom';
import { Wrapper, Column, Row, RowHeader, RowContent, List } from './styles';

import { links } from './data';

const FooterList = () => (
    <Wrapper>
        {links.map(({cols}, index) => (
            <Column key={index}>
                {cols.map(({title, link, rows}, index) => (
                    <Row key={index}>
                        <RowHeader className={index !== 0 ? 'border' : ''}>
                            {title &&<h5><Link to={link}>{title}</Link></h5>}
                        </RowHeader>
                            {rows.map(({title, links}, index) => (
                                <RowContent key={index}>
                                    <List>
                                        {title &&<h6>{title}</h6>}
                                        <ul>
                                            {links.map(({title, link}, index) => (
                                                <li key={index}>
                                                    <Link to={link}>{title}</Link>
                                                </li>
                                            ))}
                                        </ul>
                                    </List>
                                </RowContent>
                            ))}
                    </Row>
                ))}
            </Column>
        ))}
    </Wrapper>
);

export { FooterList };