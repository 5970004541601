import styled from "styled-components";
import { media } from "themes/media";

const Wrapper = styled.div`
    width: auto;
    display: flex;
    gap: 0 45px;
    align-items: center;
    a{
        width: 27px;
        height: 27px;
        display: block;
        margin: 0;
        padding: 0;
        img{
            width: 100%;
        }
    }
    ${media.up('lg')}{
        gap: 0 60px;
        a{
            width: 32px;
            height: 32px;
        }
    }
`;

export { Wrapper };