export const links = [
    {
        id: 1,
        title: 'Blog',
        link: '/blog',
        out: false,
    },
    {
        id: 2,
        title: 'Privacy Policy',
        link: '/privacy-policy',
        out: false,
    },
    {
        id: 3,
        title: 'Whistleblowers Portal',
        link: '/whistleblowers-portal',
        out: true,
    }
]