import { FunctionComponent } from "react";
import { IButton } from "./interfaces";
import { Wrapper } from "./styles";

const Button:FunctionComponent<IButton> = ({color, children, handleClick}) => (
    <Wrapper color={color} onClick={() => handleClick ? handleClick() : ''}>
        {children}
    </Wrapper>
);

export { Button };