import styled from "styled-components";
import { media } from "themes/media";

const Wrapper = styled.button`
    background: ${({theme}) => theme.colors.primaryOrange};
    border-radius: 2px;
    width: 148px;
    height: 67px;
    border: none;
    outline: none;
    margin: 0;
    padding: 0;
    font-size: 18px;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0em;
    color: ${({theme}) => theme.colors.white};
    cursor: pointer;
    transition: width 0.5s, height 0.5s, margin 0.5s;
    &:active{
        width: 120px;
        height: 47px;
        margin: 77px auto 10px;
    }
    ${media.up('lg')}{
        font-size: 20px;
        line-height: 28px;
        width: 160px;
        height: 70px;
        &:active{
            height: 40px;
            margin: 97px auto 15px;
        }
    }
`;

export { Wrapper };

