import { useDetectClickOutside } from 'react-detect-click-outside';
import { Wrapper } from "./styles";

const langs = [
    {
        id: 1,
        name: 'EN',
        code: 'en',
    },
    {
        id: 2,
        name: 'PL',
        code: 'pl',
    },
];

const LangList = ({activeLang, closeDropdown, changeActiveElement}) => {
    const ref = useDetectClickOutside({ onTriggered: closeDropdown });

    return (
        <Wrapper ref={ref}>
            <ul>
                {langs.map((lang) => (
                    <li key={lang.id} className={activeLang === lang.name ? 'active' : ''} onClick={() => changeActiveElement(lang)}>{lang.name}</li>
                ))}
            </ul>
        </Wrapper>
    )
};

export { LangList };