import styled from "styled-components";
import { media } from "themes/media";

const Wrapper = styled.div`
    width: 100%;
    ${media.up('lg')} {
        padding: 0 120px;
        max-width: 1776px;
        margin: 0 auto;
    }
`;

export { Wrapper };