import styled from "styled-components";
import { media } from "themes/media";
import {Top as SoloTop} from 'components/molecules/SoloMilestone/styles';

const Outline = styled.div`
    width: 100%;
    overflow: hidden;
    margin-top: 92px;
`;

const Wrapper = styled.div`
    width: 100%;
    padding: 0;
    margin: 0 auto;
    max-width: 1606px;
`;

const Top = styled.div`
    width: 100%;
    padding: 0 30px;
    margin: 0 0 56px;
    h5{
        font-size: 39px;
        font-weight: 400;
        line-height: 54px;
        letter-spacing: -0.01em;
        text-align: left;
        color: ${({theme}) => theme.colors.black};
        margin: 0 0 20px;
        padding: 0;
    }
    ${media.up('lg')} {
        display: flex;
        justify-content: space-between;
        align-items: stretch;
        padding: 0 120px;
        margin: 0 0 -140px;
        h5{
            max-width: 750px;
            font-size: 58px;
            line-height: 76px;
        }
    } 
`;

const Content = styled.div`
    width: 100%;
    padding-left: 30px;
    position: relative;
    ${media.up('lg')} {
        padding-left: 120px;
    }
    .swiper{
        padding-top: 80px;
        align-items: stretch;
        .swiper-wrapper{
            transition-duration: 2s !important;
            transform: translate3d(0px,0,0) !important;
            .swiper-slide{
                transition: width 0.5s, margin 0.5s, transform .7s;
                transition-timing-function: ease-in-out;
                &:first-child{
                  ${SoloTop} {
                      &::after{
                        left: 0;
                      }
                  }
                } 
                &:last-child{
                  ${SoloTop} {
                      &::after{
                        right: 0;
                      }
                  }
                }
            }
        }
        ${media.up('lg')} {
            padding-top: 196px;
            overflow: visible;
        }
    }
`

export { Outline, Wrapper, Top, Content };