import styled from 'styled-components';

const Wrapper = styled.button`
    display: inline-block;
    box-sizing: border-box;
    cursor: pointer;
    border: none;
    padding: 0;
    margin: 0;
    background: transparent;
`

export { Wrapper }; 