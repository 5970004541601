import { Link } from "react-router-dom";
import { Wrapper } from "./styles";

const NavigationLinks = () => (
    <Wrapper>
        <li>
            <Link to="/competencies">
                Competencies
            </Link>
        </li>
        <li>
            <Link to="/industries">
                Industries
            </Link>
        </li>
        <li>
            <Link to="/products-and-services">
                Products and services
            </Link>
        </li>
        <li>
            <Link to="/case-studies">
                Case studies
            </Link>
        </li>
        <li>
            <Link to="/company">
                Company
            </Link>
        </li>
    </Wrapper>
);

export { NavigationLinks };