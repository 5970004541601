import { useDetectClickOutside } from 'react-detect-click-outside';
import { Wrapper } from "./styles";

const DropDown = ({locations, activeLocation, closeDropdown, changeActiveElement}) => {
    const ref = useDetectClickOutside({ onTriggered: closeDropdown });

    const changeLocation = (country, city) => {
        const location = {
            country,
            city
        };

        changeActiveElement(location);
    }

    return (
        <Wrapper ref={ref}>
            <ol>
                {locations.map((country) => (
                    <li key={country.id}>
                        <span>{country.name}</span>
                        {country.cities.length > 0 &&<ul>
                            {country.cities.map(({id, name}) => (
                                <li key={id} onClick={() => changeLocation(country.name, name)}>
                                    <span className={activeLocation.country === country.name && activeLocation.city === name ? 'active' : ''}>
                                        {name}
                                    </span>
                                </li>
                            ))}
                        </ul>}
                    </li>
                ))}
            </ol>
        </Wrapper>
    )
};

export { DropDown }; 