import styled from "styled-components";
import { media } from "themes/media";

const Wrapper = styled.div`
    width: 100%;
    margin: 82px 0 0;
    padding: 0 30px;
    text-align: center;
    h4{
        margin: 0 auto;
    }
    ${media.up('lg')}{
        margin: 200px 0 0;
    }
`;

const Logos = styled.div`
    width: 100%;
    margin: 92px 0 81px;
    ${media.up('lg')}{
        width: 1130px;
        margin: 200px auto;
    }
`;

const Contact = styled.div`
    width: 100%;
    text-align: center;
    margin: 48px 0 0;
    button{
        margin-top: 67px;
    }
    ${media.up('lg')}{
        margin: 80px 0 0;
        button{
            margin-top: 80px;
        }
    }
`;

const Slider = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    overflow: hidden;
    margin: 0 auto;
    .solo-row{
        flex: 1 0 calc((100% - (130px * 3)) / 4);
        max-width: calc((100% - (130px * 3)) / 4);
        box-sizing: border-box;
        padding: 0;
        position: relative;
        width: auto;
    }

    .solo-row img{
        max-width: 100%;
    }

    .solo-row .s__slide{
        top: 0;
        width: 100%;
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: top 0.4s, opacity 0.4s;
    }

    .solo-row .s__active{
        top: 0;
        opacity: 1;
    }

    .solo-row .s__next{
        opacity: 0;
    }

    .solo-row .move-active{
        top: -20px;
        opacity: 0;
    }

    .solo-row .move-next{
        top: 0;
        opacity: 1;
        transition-delay: 0.2s;
    }
`

export { Wrapper, Logos, Contact, Slider };