import styled from "styled-components";
import { media } from "themes/media";

const Wrapper = styled.div`
    width: 100%;
    margin: 0;
    padding: 0;
    cursor: pointer;
    ${media.up('lg')}{
        position: relative;
    }
`;

const SelectBox = styled.div`
    width: 100%;
    box-sizing: border-box;
`;

const WrapperTop = styled.div`
    width: 100%;
    padding: 0 0 15px;
    border-bottom: 1px solid #CFCFCF;
    p{
        font-size: 18px;
        font-weight: 400;
        line-height: 25px;
        letter-spacing: 0em;
        margin: 0;
        padding: 0;
        color: ${({theme}) => theme.colors.black};
    }
    ${media.up('lg')}{
        display: flex;
        justify-content: end;
        padding: 0 0 41px;
        p{
            font-size: 36px;
            line-height: 48px;
        }
    }
`;

const WrapperBottom = styled.div`
    width: 100%;
    padding: 15px 0 0;
    display: flex;
    justify-content: space-between;
    p{
        font-size: 18px;
        font-weight: 400;
        line-height: 25px;
        letter-spacing: 0em;
        text-align: center;
        margin: 0;
        padding: 0;
        color: ${({theme}) => theme.colors.black};
    }
    ${media.up('lg')}{
        padding: 41px 0 0;
        p{
            font-size: 21px;
            line-height: 30px;
            &:nth-child(2){
                display: none;
            }
        }
    }
`;

export { Wrapper, SelectBox, WrapperTop, WrapperBottom };