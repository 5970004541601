import styled from "styled-components";
import { media } from "themes/media";

import CloseIcon from 'assets/icons/close_popup.svg';

const Outside = styled.div`
    position: fixed;
    width: calc(100% - 60px);
    height: calc(100vh - 60px);
    top: 30px;
    left: 30px;
    z-index: 3000;
    ${media.up('lg')}{
        max-width: 1260px;
        left: calc(50% - 630px);
        top: 50px;
    }
`;

const ContentScroll = styled.div`
    width: 100%;
    max-height: calc(100vh - 120px);
    overflow: auto;
    &::-webkit-scrollbar {
        display: none;
    }
    ${media.up('lg')}{
        max-height: calc(100vh - 208px);
    }
`;

const FullContent = styled.div`
    width: 100%;
    box-sizing: border-box;
    ${media.up('lg')}{
        display: flex;
        align-items: start;
        justify-content: space-between;
        gap: 22px;
    }
`

const HalfContent = styled.div`
    width: 100%;
    box-sizing: border-box;
    ${media.up('lg')}{
        display: flex;
        flex-wrap: wrap;
        width: auto;
        &:nth-child(2){
            justify-content: end;
        }
    }
`;

const Wrapper = styled.div`
    position: relative;
    width: 100%;
    background: ${({theme}) => theme.colors.white};
    border-radius: 5px;
    box-shadow: 0px 18px 49px rgba(0, 0, 0, 0.07), 0px 5.42647px 14.7721px rgba(0, 0, 0, 0.0456112), 0px 2.25388px 6.13556px rgba(0, 0, 0, 0.035), 0px 0.815184px 2.21911px rgba(0, 0, 0, 0.0243888);
    padding: 30px;
    ${media.up('lg')}{
        padding: 64px 104px;
    }
    h6{
        font-size: 39px;
        font-weight: 400;
        line-height: 54px;
        letter-spacing: -0.01em;
        text-align: left;
        color: ${({theme}) => theme.colors.black};
        margin: 0 0 24px;
        padding: 0;
        span{
            display: inline-block;
            height: 43px;
            width: 58px;
            position: relative;
            margin: 0;
            padding: 0;
            img{
                position: absolute;
                height: 58px;
                width: 58px;
                margin: 0;
                padding: 0;
            }
        }
    }
    ${media.up('lg')}{
        h6{
            font-size: 58px;
            line-height: 75px;
            margin: 0 0 149px;
            span{
                height: 59px;
                width: 94px;
                img{
                    width: 94px;
                    height: 94px;
                }
            }
        }
    }
`;

const CloseButton = styled.div`
    width: 33px;
    height: 33px;
    position: absolute;
    right: -16.5px;
    top: -16.5px;
    padding: 9px;
    background: ${({theme}) => theme.colors.white};
    box-shadow: 0px 18.4705px 50.2808px rgba(0, 0, 0, 0.07), 0px 5.56832px 15.1582px rgba(0, 0, 0, 0.0456112), 0px 2.31279px 6.29594px rgba(0, 0, 0, 0.035), 0px 0.836492px 2.27712px rgba(0, 0, 0, 0.0243888);
    border-radius: 5px;
    cursor: pointer;
    span{
        display: block;
        width: 15px;
        height: 15px;
        background: url(${CloseIcon});
        background-size: 15px;
    }
`;

const Content = styled.div`
    width: 100%;
    box-sizing: border-box;
    margin: 25px 0 0;
    p{
        text-align: left;
        padding: 0;
    }
    ${media.up('lg')}{
        order: 0;
        p{
            max-width: 390px;
            font-size: 21px;
            line-height: 29px;
            padding: 0;
            margin: 0 0 48px;
        }
    }
`;

const Form = styled.form`
    width: 100%;
    margin: 36px 0 0;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    input, textarea, button{
        flex: 1 0 100%;
    }
    ${media.up('lg')}{
        width: 486px;
        margin: 0;
    }
`;

const Button = styled.button`
    font-size: 18px;
    font-weight: 400;
    letter-spacing: 0em;
    box-shadow: 0px 17.2286px 46.9px rgba(0, 0, 0, 0.07), 0px 5.19391px 14.139px rgba(0, 0, 0, 0.0456112), 0px 2.15728px 5.87261px rgba(0, 0, 0, 0.035), 0px 0.780248px 2.12401px rgba(0, 0, 0, 0.0243888);
    border: 1px solid ${({theme}) => theme.colors.primaryOrange};
    background: ${({theme}) => theme.colors.white};
    color: ${({theme}) => theme.colors.black};
    border-radius: 5px;
    height: 66px;
    width: 100%;
    margin: 18px 0 0;
    cursor: pointer;
    ${media.up('lg')}{
        width: auto;
        background: ${({theme}) => theme.colors.primaryOrange};
        color: ${({theme}) => theme.colors.white};
        padding: 0 29px;
        height: 70px;
    }
`;

const FormFooter = styled.div`
    width: 100%;
    margin: 46px 0 0;
    p{
        text-align: left;
        padding: 0;
        font-size: 10px;
        font-weight: 400;
        line-height: 14px;
        letter-spacing: 0em;
        text-align: left;
        color: ${({theme}) => theme.colors.primaryGray};
        a{
            color: ${({theme}) => theme.colors.primaryOrange};
            text-decoration: none;
            cursor: pointer;
        }
    }
    ${media.up('lg')}{
        margin: 76px 0 0;
        p{
            max-width: 100%;
            font-size: 14px;
            line-height: 20px;
        }
    }
`

export { Outside, Wrapper, CloseButton, Content, ContentScroll, Form, Button, FormFooter, HalfContent, FullContent };