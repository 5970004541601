import { useDetectClickOutside } from 'react-detect-click-outside';
import { Outside, Wrapper, CloseButton, Content, ContentScroll, Form, Button, FormFooter, HalfContent, FullContent} from './styles';

import { useFormik } from "formik";
import { FormContactValidation } from './validation';

import { PersonInfo } from 'components/molecules/PersonInfo';
import { Description } from 'components/atoms/Description';
import { Input } from 'components/atoms/Input';
import { Textarea } from 'components/atoms/Textarea';

import ChatIcon from 'assets/icons/chat.svg';
import Avatar from 'assets/images/avatar.png';

const ContactForm = ({closeModal, handleClose}) => {
    const ref = useDetectClickOutside({ onTriggered: closeModal});

    const formik = useFormik({
        initialValues: {
            name: '',
            email: '',
            company: '',
            content: '',
        },
        validationSchema: FormContactValidation,
        onSubmit: (values, {resetForm}) => {

        }
    });

    return (
        <>
            <Outside ref={ref}>
                <Wrapper>
                    <CloseButton onClick={() => handleClose(false)}><span></span></CloseButton>
                    <ContentScroll>
                        <FullContent>
                            <HalfContent>
                                <h6>Let’s<span><img src={ChatIcon} alt="chat icon"/></span>talk</h6>
                                <PersonInfo
                                    image={Avatar}
                                    name="Kinga Nawrocka"
                                    position="Sales"
                                />
                                <Content>
                                    <Description type="smaller" content="Hi, I'm Kinga Nawrocka and I'm working in the sales team. We're here to help you find the right solutions so you can grow your business, increase efficiency and deliver the future." />
                                </Content>
                            </HalfContent>
                            <HalfContent>
                                <Form onSubmit={() => formik.handleSubmit()}>
                                    <Input
                                        type="text"
                                        name="name"
                                        value={formik.values.name}
                                        error={formik.errors.name}
                                        placeholder="Your name"
                                        handleChange={formik.handleChange}
                                    />
                                    <Input
                                        type="email"
                                        name="email"
                                        value={formik.values.email}
                                        error={formik.errors.email}
                                        placeholder="Your business e-mail"
                                        handleChange={formik.handleChange}
                                    />
                                    <Input
                                        type="text"
                                        name="company"
                                        value={formik.values.company}
                                        error={formik.errors.company}
                                        placeholder="Company name"
                                        handleChange={formik.handleChange}
                                    />
                                    <Textarea
                                        name="content"
                                        value={formik.values.content}
                                        error={formik.errors.content}
                                        placeholder="Your message"
                                        handleChange={formik.handleChange}
                                    />
                                </Form>
                                <Button type="submit" onClick={() => formik.handleSubmit()}>Send message</Button>
                            </HalfContent>
                        </FullContent>
                        <FormFooter>
                            <Description
                                type="smaller"
                                content="By submitting your data, you confirm that you have read the <a>Privacy Policy</a>. Providing your e-mail address and phone number means that you agree to be contacted by Billennium. You can withdraw your consent at any time by sending an e-mail to: <a>daneosobowe@billennium.com</a>. This site is protected by reCAPTCHA and the Google <a>Privacy Policy</a> and <a>Terms of Service</a> apply."
                            />
                        </FormFooter>
                    </ContentScroll>
                </Wrapper>
            </Outside>
        </>
    );
};

export { ContactForm };