import bestWorkplaces from "assets/images/best-workplaces.png";
import aws from "assets/images/aws.png";
import greatPlace from "assets/images/great-place.png";
import google from "assets/images/google.png";
import SliderOne from 'assets/images/slider_one.png';
import SliderTwo from 'assets/images/slider_two.png';
import SliderThree from 'assets/images/slider_three.png';
import VideoOne from 'assets/videos/slider_one.mp4';
import avatar from 'assets/icons/avatar.svg';
import home from 'assets/icons/house.svg';
import ImageOne from 'assets/images/image_one.jpg';
import ImageTwo from 'assets/images/image_two.jpg';
import ImageThree from 'assets/images/image_three.jpg';
import MicrosoftLogo from 'assets/images/microsoft_logo.png';
import AwsLogo from 'assets/images/aws_logo.png';
import GoogleLogo from 'assets/images/google_logo.png';
import VonageLogo from 'assets/images/vonage_logo.png';

export const milestones = [
    {
        title: '',
        year: '2022',
        content: 'We implemented several ISO standards to strengthen these vital values.',
        image: bestWorkplaces,
    },
    {
        title: '',
        year: '2021',
        content: 'We implemented several ISO standards to strengthen these vital values. We implemented several ISO standards to strengthen these vital values.',
        image: aws,
    },
    {
        title: '',
        year: '2020',
        content: 'We implemented several ISO standards to strengthen these vital values. We implemented',
        image: greatPlace,
    },
    {
        title: '',
        year: '2019',
        content: 'We implemented several ISO standards to strengthen these vital values.',
        image: google,
    },
    {
        title: '',
        year: '2022',
        content: 'We implemented several ISO standards to strengthen these vital values.',
        image: bestWorkplaces,
    },
    {
        title: '',
        year: '2021',
        content: 'We implemented several ISO standards to strengthen these vital values. We implemented several ISO standards to strengthen these vital values.',
        image: aws,
    },
    {
        title: '',
        year: '2020',
        content: 'We implemented several ISO standards to strengthen these vital values. We implemented',
        image: greatPlace,
    },
    {
        title: '',
        year: '2019',
        content: 'We implemented several ISO standards to strengthen these vital values.',
        image: google,
    },
];

export const sliders = [
    {
        id: 1,
        title: `We currently have over 1809 <span><img src="${avatar}"/></span>specialists from various IT areas on board.`,
        content: 'Strong competitive<br/> <a>advantage ↗</a> with technology',
        image: SliderOne,
        video: VideoOne,
    },
    {
        id: 2,
        title: `11 <span><img src="${home}"/></span> locations around the world that you can use however you need.`,
        content: 'Global <a>offices ↗</a> of Billennium',
        image: SliderTwo,
    },
    {
        id: 3,
        title: `We have learned how to offer our clients the highest quality of IT service`,
        content: 'Read more <a>about industries ↗</a>',
        image: SliderThree,
    }
];

export const images = [
    {
        id: 1,
        alt: 'image alt',
        image: ImageOne,
    },
    {
        id: 2,
        alt: 'image alt',
        image: ImageTwo,
    },
    {
        id: 3,
        alt: 'image alt',
        image: ImageThree,
    },
    {
        id: 4,
        alt: 'image alt',
        image: ImageOne,
    },
    {
        id: 5,
        alt: 'image alt',
        image: ImageTwo,
    },
    {
        id: 6,
        alt: 'image alt',
        image: ImageThree,
    }
];

export const logos = [
    {
        id: 1,
        active: false,
        alt: 'microsoft logo',
        image: MicrosoftLogo,
    },
    {
        id: 2,
        active: false,
        alt: 'aws logo',
        image: AwsLogo,
    },
    {
        id: 3,
        active: false,
        alt: 'google logo',
        image: GoogleLogo,
    },
    {
        id: 4,
        active: false,
        alt: 'vonage logo',
        image: VonageLogo,
    },
];