import styled from 'styled-components';
import { media } from 'themes/media';
import { ISmallBanner } from './interfaces';

const Wrapper = styled.div<ISmallBanner>`
    width: 280px;
    height: 345px;
    background: url("${({image}) => image}") no-repeat;
    background-size: cover;
    border-radius: 5px;
    ${media.up('lg')} {
        width: 387px;
        height: 500px;
    }
`;

export { Wrapper };