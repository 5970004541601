import styled from "styled-components";
import { media } from "themes/media";

const Wrapper = styled.button`
    padding: 15px 34px;
    background: ${({theme}) => theme.colors.white};
    box-shadow: 0px 18px 49px rgba(0, 0, 0, 0.07), 0px 5.42647px 14.7721px rgba(0, 0, 0, 0.0456112), 0px 2.25388px 6.13556px rgba(0, 0, 0, 0.035), 0px 0.815184px 2.21911px rgba(0, 0, 0, 0.0243888);
    border-radius: 5px;
    color: ${({color}) => color};
    font-size: 18px;
    font-weight: 400;
    letter-spacing: 0em;
    display: inline-block;
    border: none;
    cursor: pointer;
    ${media.up('lg')} {
        box-shadow: none;
        padding: 0;
        background: transparent;
        font-size: 16px;
    }
    ${media.up('xl')} {
        font-size: 20px;
    }
`;

export { Wrapper };