import styled from "styled-components";
import { media } from "themes/media";

const Wrapper = styled.div`
    width: auto;
    ${media.up('lg')}{
        margin-top: -47.5px;
        z-index: 5;
        position: relative;
    }
`

const Solo = styled.span`
    position: relative;
    display: inline-block;
    padding: 22px 7px 0;
    border-top: 1px solid ${({theme}) => theme.colors.greyBorder};
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    cursor: pointer;
    color: ${({theme}) => theme.colors.black};
    &::before{
        content: '';
        position: absolute;
        width: 0;
        height: 1.5px;
        top: -1px;
        right: 0;
        background-color: ${({theme}) => theme.colors.black};
        transition: width 350ms ease-out;
        transition-delay: 150ms;
    }
    &.active{
        &::before{
            left: 0;
            width: 100%;
            transition: width 500ms ease-out;
        }
    }
    &.active-remove{
        &::before{
            left: 0;
            width: 0;
            transition: width 350ms ease-out;
            transition-delay: 150ms;
        }
    }
    &.active-prev{
        &::before{
            right: 0;
            width: 100%;
            transition: width 500ms ease-out;
        }
    }
    &.prev-long{
        &::before{
            left: 0;
            width: 300%;
            z-index: 5;
            transition: width 250ms, left 250ms;
            animation: moveline forwards;
            animation-duration: 350ms;
            animation-delay: 150ms;
        }
    }
    &.active-wait{
        &::before{
            left: 0;
            width: 100%;
            transition: width 150ms ease-out;
            transition-delay: 50ms;
        }
    }
    @keyframes moveline {
        from {
            left: 0;
            width: 300%;
        }
        to{
            left: 300%;
            width: 0;
        }
    }
    ${media.up('lg')} {
        font-size: 18px;
        padding: 22px 17.5px 0;
    }
`

export { Wrapper, Solo };

