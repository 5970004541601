import styled from "styled-components";
import { media } from "themes/media";

const Wrapper = styled.div`
    width: 100%;
    margin: 78px 0 0;
    box-sizing: border-box;
    text-align: center;
    ${media.up('lg')}{
        margin: 200px 0 0;
    }
`;

const Top = styled.div`
    width: 100%;
    p{
        font-size: 18px;
        font-weight: 400;
        line-height: 25px;
        letter-spacing: 0em;
        text-align: center;
        max-width: 748px;
    }
    ${media.up('lg')}{
        p{
            font-size: 20px;
            line-height: 28px;
        }
    }
`

const GallerySmall = styled.div`
    max-width: 1400px;
    width: 100%;
    margin: 0 auto;
    ${media.up('xxl')} {
        display: none;
    }
`

const GalleryBig = styled.div`    
    width: 100%;
    margin: 0 auto;
    display: none;
    ${media.up('xxl')} {
        display: block;
    }
`

const Images = styled.div`
    width: 100%;
    margin: 60px 0 0;
    box-sizing: border-box;
    padding: 0 30px;
    .swiper-wrapper{
        align-items: center;
    }
    ${media.up('lg')}{
        margin: 200px 0;
        padding: 0 40px;
    }
    ${media.up('xxl')}{
        margin: 200px 0;
        padding: 0;
    }
`;

export { Wrapper, Top, Images, GallerySmall, GalleryBig };