import { useState } from "react";
import i18next from "i18next";
import cookies from 'js-cookie';
import { useTranslation } from "react-i18next";

import { Wrapper, WrapperLang } from "./styles";
import { Button } from "components/atoms/Button";
import { LangList } from "components/atoms/LangList";

import { theme } from "themes/theme";

import { connect } from "react-redux";
import { toggleContactForm } from "actions";

const NavigationButtonsComponent = ({toggleContactForm}) => {
    const { t } = useTranslation();
    const [isOpenLang, setOpenLang] = useState(false);
    const [activeLang, setActiveLang] = useState(cookies.get('i18next')?.toUpperCase() || 'PL');

    const closeDropDown = () => setOpenLang(false);

    const changeLang = (lang) => {
        setActiveLang(lang.name);
        i18next.changeLanguage(lang.code);
        setOpenLang(false);
    }

    return (
        <Wrapper>
            <Button color={theme.colors.primaryOrange} handleClick={() => toggleContactForm()}>{ t('Contact us') }</Button>
            <WrapperLang>
                <Button color={theme.colors.primaryGray} handleClick={() => setOpenLang(true)}>
                    {`${activeLang}`}
                </Button>
                {isOpenLang &&
                    <LangList
                        activeLang={activeLang}
                        closeDropdown={closeDropDown}
                        changeActiveElement={(lang) => changeLang(lang)}
                    />
                }
            </WrapperLang>
        </Wrapper>
    )
};

const mapDispatchToProps = (dispatch) => ({
    toggleContactForm: () => dispatch(toggleContactForm(true)),
})

export const NavigationButtons = connect(null, mapDispatchToProps)(NavigationButtonsComponent);