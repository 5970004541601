import styled from "styled-components";
import { media } from "themes/media";

const Wrapper = styled.div`
    width: auto;
    display: flex;
    justify-content: start;
    gap: 11px;
    align-items: center;
    position: absolute;
    top: 0;
    ${media.up('lg')}{
        right: 40px;
    }
`;

const Button = styled.button`
    width: 44px;
    height: 40px;
    background: ${({theme}) => theme.colors.white};
    border-radius: 5px;
    box-shadow: 0px 18px 49px rgba(0, 0, 0, 0.07), 0px 5.42647px 14.7721px rgba(0, 0, 0, 0.0456112), 0px 2.25388px 6.13556px rgba(0, 0, 0, 0.035), 0px 0.815184px 2.21911px rgba(0, 0, 0, 0.0243888);
    font-size: 18px;
    font-weight: 400;
    letter-spacing: 0em;
    text-align: center;
    color: ${({theme}) => theme.colors.black};
    border: none;
    cursor: pointer;
    ${media.up('lg')}{
        width: 74px;
        height: 70px;
        font-size: 20px;
    }
`;

export { Wrapper, Button }