import styled from 'styled-components';
import { media } from 'themes/media';

import { ErrorInput } from './interface';

const Wrapper = styled.div<ErrorInput>`
    width: 100%;
    box-sizing: border-box;
    height: 45px;
    border: 1px solid ${({error}) => error ? 'rgba(255, 83, 83, 1)' : '#CFCFCF'};
    background: ${({error}) => error ? 'rgba(255, 202, 202, 1)' : '#ffffff'};
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
    padding: 0 20px;
    align-items: center;
    input{
        background: transparent;
        font-size: 14px;
        height: 43px;
        width: 100%;
        color: ${({theme}) => theme.colors.black};
        border: none;
        outline: none;
        &:placeholder{
            color: ${({theme}) => theme.colors.primaryGray};
        }
    }
    ${media.up('lg')}{
        height: 62px;
        input{
            height: 60px;
        }
    }
`

export { Wrapper }