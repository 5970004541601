import styled from "styled-components";
import { media } from "themes/media";

const Wrapper = styled.div`
    width: 100%;
    padding: 0 20px;
    p{
        font-size: 18px;
        font-weight: 400;
        line-height: 25px;
        letter-spacing: 0em;
        text-align: center;
        color: ${({theme}) => theme.colors.black};
        margin: 0 0 20px;
        padding: 0;
    }
    ${media.up('lg')} {
        display: flex;
        justify-content: space-between;
        align-items: center;
        max-width: 1200px;
        margin: 0 auto;
        padding: 0 102px;
        p{
            margin: 0;
            font-size: 21px;
            line-height: 29px;
        }
    }
`;

const List = styled.div`
    width: 100%;
    display: flex;
    box-sizing: border-box;
    justify-content: center;
    align-items: center;
    gap: 0 18px;
    span{
        font-size: 14px;
        font-weight: 400;
        line-height: 12px;
        letter-spacing: 0em;
        text-align: left;
        margin: 0;
        padding: 0;
        cursor: pointer;
        color: ${({theme}) => theme.colors.primaryGray};
        &.active{
            color: ${({theme}) => theme.colors.black};
        }
    }
    ${media.up('lg')} {
        width: auto;
        justify-content: start;
        gap: 0 60px;
        span{
            font-size: 21px;
            line-height: 29px;
            opacity: 0.3;
            color: ${({theme}) => theme.colors.black};
            transition: opacity 0.5s;
            &.active{
                opacity: 1;
            }
        }
    }
`

export { Wrapper, List };