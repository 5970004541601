import styled, { css } from "styled-components";
import { media } from "themes/media";

import { ISoloSliderWrapper } from "./interfaces";

const Wrapper = styled.div<ISoloSliderWrapper>`
    width: 100%;
    box-sizing: border-box;
    margin-top: ${({top_break}) => top_break || 0};
    ${({type_slide}) => {
        switch(type_slide){
            case 'solo':
                return css`
                    padding: 0 30px;
                `
            default:
                return css`
                    padding: 42px 0;
                `
        }
    }};
    img{
        max-width: 100%;
    }
    ${media.up('md')} {
        display: flex;
        justify-content: space-between;
        ${({type_slide}) => {
        switch(type_slide){
            case 'solo':
                return css`
                    align-items: center;
                `
            default:
                return css``
            }
        }};
        align-items: center;
    }
    ${media.up('lg')} {
        padding: 0;
        max-width: 1526px;
        margin: ${({top_break}) => `calc(${top_break} + 148px)` || 0} auto 0;
        img{
            max-width: 387px;
        }
    }
`;

const Content = styled.div`
    width: 100%;
    box-sizing: border-box;
    h3{
        font-size: 39px;
        font-weight: 400;
        line-height: 54px;
        letter-spacing: -0.01em;
        text-align: left;
        color: ${({theme}) => theme.colors.black};
        margin: 0 0 50px;
        padding: 0;
        span{
            display: inline-block;
            height: 39px;
            width: 54px;
            line-height: 54px;
            position: relative;
            margin: 0;
            padding: 0;
            img{
                position: absolute;
                height: 54px;
                width: 54px;
                margin: 0;
                padding: 0;
            }
        }
    }
    p{
        font-size: 20px;
        font-weight: 400;
        line-height: 29px;
        letter-spacing: 0em;
        text-align: left;
        color: ${({theme}) => theme.colors.black};
        margin: 0 0 68px;
        padding: 0;
        max-width: 611px;
        a{
            position: relative;
            display: inline-block;
            height: 16px;
            top: -5px;
            font-size: 13px;
            font-weight: 400;
            line-height: 12px;
            letter-spacing: 0em;
            text-align: left;
            color: ${({theme}) => theme.colors.primaryOrange};
            cursor: pointer;
            margin: 0;
            padding: 0;
            border-bottom: 1px solid ${({theme}) => theme.colors.primaryOrange};
        }
    }
    ${media.up('sm')} {
        width: auto;
        h3{
            max-width: 610px;
        }
    }
    ${media.up('lg')} {
        h3{
            font-size: 58px;
            line-height: 76px;
            margin-bottom: 80px;
            span{
                height: 60px;
                width: 86px;
                img{
                    height: 86px;
                    width: 86px;
                }
            }
        }
        p{
            font-size: 36px;
            line-height: 45px;
            a{
                height: 38px;
                top: -8px;
                font-size: 20px;
                line-height: 45px;
            }
        }
    } 
`

const Video = styled.video`
    object-fit: cover;
    z-index: 10;
    width: 280px;
    height: 345px;
    border-radius: 5px;
    ${media.up('lg')} {
        width: 387px;
        height: 500px;
    }
`

export { Wrapper, Content, Video };