import ImageOne from 'assets/images/image_one.jpg';
import ImageTwo from 'assets/images/image_two.jpg';
import ImageThree from 'assets/images/image_three.jpg';

import { useEffect, useState } from 'react';
import { Wrapper, Slider, Gallery, Col, SliderWrapper } from './styles';

import { useCurrentWidth } from 'hooks/useCurrentWidth';

const images = [
  {
    id: 1,
    image: ImageOne,
    type: 'solo-slider'
  },
  {
    id: 2,
    image: ImageTwo,
    type: 'solo-slider'
  },
  {
    id: 3,
    image: ImageThree,
    type: 'solo-slider solo-slider-longer'
  }
]

const GalleryImages = () => {
  const width = useCurrentWidth();
  const [counter, setCounter] = useState(0);

  useEffect(() => {
    const sliders = document.getElementsByClassName('solo-slider');
    const sliderContent = document.getElementById('slider-content');
    if(sliders.length > 0 && sliderContent !== null){
      const width = sliders[0]['offsetWidth'] + sliders[1]['offsetWidth'];
      sliderContent.setAttribute('style',`width: calc(100% + ${width}px + 242px); transform: translate3d(calc(-${width}px - 363px), 0px, 0px);`);
    }
  }, [width])

  useEffect(() => {
    setTimeout(() => {
      setTimeout(() => {
        const sliders = document.getElementsByClassName('solo-slider');
        const sliderContent = document.getElementById('slider-content');
        if(sliders.length > 0 && sliderContent !== null){
          const width = sliders[0]['offsetWidth'] + sliders[1]['offsetWidth'];
          sliderContent.setAttribute('style',`width: calc(100% + ${width}px + 242px); transform: translate3d(calc(-${sliders[0]['offsetWidth']}px - 242px), 0px, 0px); transition: transform 2s;`);
        }
      }, 700);

      const sliders = document.getElementsByClassName('solo-slider');
      for(let i = sliders.length - 1; i >= 2; i--){
        setTimeout(() => {
          sliders[i].classList.add('slider-move');
        }, 1000 - (100 * i));
        setTimeout(() => {
          sliders[i].classList.remove('slider-move');
        }, 2000 - (100 * i));
      }
      setTimeout(() => {
        (counter > 0) ? setCounter(prev => prev - 1) : setCounter(() => images.length - 1);
        const sliders = document.getElementsByClassName('solo-slider');
        const sliderContent = document.getElementById('slider-content');
        if(sliders.length > 0 && sliderContent !== null){
          sliders[sliders.length - 1].remove();
          const el = document.createElement('div');
          el.classList.add('solo-slider');
          (counter === 2) && el.classList.add('solo-slider-longer');
          const img = document.createElement('img');
          img.src = images[counter].image;
          el.append(img);
          sliderContent.prepend(el);
          const width = sliders[0]['offsetWidth'] + sliders[1]['offsetWidth'];
          sliderContent.setAttribute('style',`width: calc(100% + ${width}px + 242px); transform: translate3d(calc(-${width}px - 363px), 0px, 0px);`);
        }
      }, 2500);
    }, 3000)
  }, [counter])

  return (
    <Wrapper>
        <SliderWrapper>
          <Slider id="slider-content">
            <div className={images[1].type}>
              <img src={images[1].image} alt="slider" />
            </div>
            <div className={images[2].type}>
              <img src={images[2].image} alt="slider" />
            </div>
            {images.map(({image, id, type}) => (
              <div key={id} className={type}>
                <img src={image} alt="slider" />
              </div>
            ))}
          </Slider>
        </SliderWrapper>
        <Gallery>
            <Col>
                <img src={images[1].image} alt="slider" />
            </Col>
            <Col>
                <img src={images[0].image} alt="slider" />
                <img src={images[2].image} alt="slider" />
            </Col>
        </Gallery>
    </Wrapper>
  )
}

export { GalleryImages };
