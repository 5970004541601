/* eslint-disable @typescript-eslint/no-unused-expressions */
import { useEffect, useRef, useState } from 'react';
import { AnimationOnScroll } from 'react-animation-on-scroll';
import { Wrapper, Logos, Contact, Slider } from "./styles";

import { Description } from "components/atoms/Description";
import { Title } from "components/atoms/Title";
import { ButtonContact } from "components/atoms/ButtonContact";

interface Slide {
  id: number;
  image: string;
  alt: string;
  active: boolean;
}

interface Row {
  slides: Array<Slide>;
}

const Partnerships = ({title, content, content_contact, logos}) => {
    const [activeSlideIndex, setActiveSlideIndex] = useState(-1);
    const myContainer = useRef(null);

    useEffect(() => {
        const slidesOnView = 4;
        let devHeight = 100;
        let getRows = () => {
            const arr: Row[] = [];
            for(let i = 0; i < slidesOnView; i++){
                let el = {
                    slides: logos
                }
                arr.push(el);
            }
            return arr;
        }
        let createSlides = (parentBox) => {
            const rows = getRows();
            for(let i = 0; i < slidesOnView; i++){
                const row = document.createElement('div');
                row.classList.add('solo-row');
                row.setAttribute('style',`height: ${devHeight}px;`);

                const active_slide = document.createElement('div');
                const active_el = rows[i].slides.filter((row, index) => index === i);
                active_slide.innerHTML = `<img src="${active_el[0].image}" alt="${active_el[0].alt}" />`;
                active_slide.classList.add('s__slide');
                active_slide.classList.add('s__active');
                active_slide.setAttribute('style',`height: ${devHeight}px;`);

                const next_slide = document.createElement('div');
                const next_el = rows[i].slides.indexOf(active_el[0]) + 1 >= rows[i].slides.length ? rows[i].slides[0] : rows[i].slides[rows[i].slides.indexOf(active_el[0]) + 1];
                next_slide.innerHTML = `<img src="${next_el.image}" alt="${next_el.alt}" />`;
                next_slide.classList.add('s__slide');
                next_slide.classList.add('s__next');
                next_slide.setAttribute('style',`height: ${devHeight}px;`);

                row.appendChild(active_slide);
                row.appendChild(next_slide);

                parentBox.appendChild(row);
            }
            setTimeout(() => {
                setActiveSlideIndex(0);
            }, 1000);
        }
        let generateAnimation = (rows) => {
            creteNewSlide(0);
            let finishTime = 200;
            for(let i = 1; i < slidesOnView; i++){
                finishTime += 200 * i;
                window.setTimeout(creteNewSlide, 200 * i, i)
            }
            window.setTimeout(modifiElements, finishTime, rows);
            let startInterval = 1000;
            for(let i = 1; i < slidesOnView; i++){
                startInterval += 500 * i;
            }
            setTimeout(() => {
                setActiveSlideIndex((prev) => {
                    if(prev + 1 >= logos.length){
                        return 0;
                    }else if(prev < -1){
                        return 1;
                    }else{
                        return prev + 1;
                    }
                });
            }, startInterval);
        }
        let creteNewSlide = (index) => {
            const activeSlide = document.getElementsByClassName('s__active');
            const nextSlide = document.getElementsByClassName('s__next');
            activeSlide[index].classList.add('move-active');
            nextSlide[index].classList.add('move-next');
        }
        let modifiElements = (rows) => {
            const moveSlides = Array.from(document.getElementsByClassName('move-active'));
            moveSlides.forEach(moveSlide => {
                moveSlide.remove();
            });

            const nextSlidesShowed = Array.from(document.getElementsByClassName('move-next'));
            nextSlidesShowed.forEach(nextSlideShowed => {
                nextSlideShowed.className = 's__slide s__active';
            });

            const rowsDiv = document.getElementsByClassName('solo-row');
            for(let i = 0; i < rowsDiv.length; i++){
                let counter = activeSlideIndex + i + 1;
                if(counter >= logos.length){
                    counter = counter - logos.length;
                }

                const next_slide = document.createElement('div');
                const active_el = rows[i].slides.filter((row, index) => index === counter);
                const next_el = rows[i].slides.indexOf(active_el[0]) + 1 >= rows[i].slides.length ? rows[i].slides[0] : rows[i].slides[rows[i].slides.indexOf(active_el[0]) + 1];
                next_slide.innerHTML = `<img src="${next_el.image}" alt="${next_el.alt}" />`;
                next_slide.classList.add('s__slide');
                next_slide.classList.add('s__next');
                next_slide.setAttribute('style',`height: ${devHeight}px;`);

                rowsDiv[i].appendChild(next_slide);
            }
        }
        const rows = getRows();
        activeSlideIndex < 0 ? createSlides(myContainer.current) : generateAnimation(rows);
    }, [activeSlideIndex, logos]);

    return (
        <Wrapper>
            <AnimationOnScroll animateOnce={true} animateIn="animate__fadeIn" duration={1.5}>
                <Title content={title} />
            </AnimationOnScroll>
            <AnimationOnScroll animateOnce={true} animateIn="animate__fadeInUp" duration={1.5}>
                <Logos>
                    <Slider ref={myContainer} id="partnerships"></Slider>
                </Logos>
            </AnimationOnScroll>
            <AnimationOnScroll animateOnce={true} animateIn="animate__fadeInUp" duration={1.5}>
                <Description content={content} />
            </AnimationOnScroll>
            <AnimationOnScroll animateOnce={true} animateIn="animate__fadeInUp" duration={1.5}>
                <Contact>
                    <Description content={content_contact} type="smaller" />
                    <ButtonContact />
                </Contact>
            </AnimationOnScroll>
        </Wrapper>
    )   
};

export { Partnerships };