import { Wrapper, Top } from "./styles";

import { Description } from "components/atoms/Description";
import { Title } from "components/atoms/Title";
import { Socials } from "components/molecules/Socials";

const FooterHeader = () => (
    <Wrapper>
        <Top>
            <Title version="bigger" content="Contact with us" />
            <Socials />
        </Top>
        <Description content="We work in a follow-the-sun (24/7/365) model." />
    </Wrapper>
);

export { FooterHeader };