export const links = [
    {
        id: 1,
        cols: [
            {
                title: 'Competencies',
                link: '/competencies',
                rows: [
                    {
                        title: 'Core Development Teams',
                        links: [
                            {
                                title: 'Software Development',
                                link: '/software-development',
                            },
                            {
                                title: 'Operation Services',
                                link: '/operation-services',
                            },
                            {
                                title: 'Workshops',
                                link: '/workshops',
                            },
                            {
                                title: 'Business Analysis',
                                link: '/business-analysis',
                            },
                            {
                                title: 'UX - Design',
                                link: '/ux-design',
                            },
                            {
                                title: 'Integration',
                                link: '/integration',
                            },
                            {
                                title: 'automation',
                                link: '/automation',
                            },
                            {
                                title: 'Modern Workplace',
                                link: '/modern-workplace',
                            },
                            {
                                title: 'Development LifeCycle Tools',
                                link: '/development-lifecycle-tools',
                            },
                            {
                                title: 'Project Management',
                                link: '/project-management',
                            },
                            {
                                title: 'Quality Assurance',
                                link: '/quality-assurance',
                            },
                            {
                                title: 'DevOps & Clouds',
                                link: '/devops-clouds',
                            },
                            {
                                title: 'Identity Management',
                                link: '/identity-management',
                            },
                        ]
                    }
                ]
            },
        ]
    },
    {
        id: 2,
        cols: [
            {
                title: '',
                link: '',
                rows: [
                    {
                        title: 'Business Services Teams',
                        links: [
                            {
                                title: 'Laboratory informatics',
                                link: '/laboratory-informatics',
                            },
                            {
                                title: 'Big Data & AI',
                                link: '/big-data-ai',
                            },
                            {
                                title: 'CRM',
                                link: '/crm',
                            },
                            {
                                title: 'BI',
                                link: '/bi',
                            },
                        ]
                    },
                    {
                        title: 'Technology Teams',
                        links: [
                            {
                                title: '.NET',
                                link: '/net',
                            },
                            {
                                title: 'Azure',
                                link: '/azure',
                            },
                            {
                                title: 'Google',
                                link: '/google',
                            },
                            {
                                title: 'Amazon',
                                link: '/amazon',
                            },
                            {
                                title: 'salesforce',
                                link: '/salesforce',
                            },
                            {
                                title: 'PHP',
                                link: '/php',
                            },
                            {
                                title: 'Angular',
                                link: '/angular',
                            },
                            {
                                title: 'React',
                                link: '/react',
                            },
                            {
                                title: 'Mobile Development',
                                link: '/mobile-development',
                            },
                            {
                                title: 'Python',
                                link: '/python',
                            },
                            {
                                title: 'Java',
                                link: '/java',
                            },
                        ]
                    }
                ]
            },
        ]
    },
    {
        id: 3,
        cols: [
            {
                title: 'Products and services',
                link: '/products-and-services',
                rows: [
                    {
                        title: 'Products',
                        links: [
                            {
                                title: 'Inperly',
                                link: '/inperly',
                            },
                            {
                                title: 'Paperless office',
                                link: '/paperless-office',
                            },
                        ]
                    },
                    {
                        title: 'Services',
                        links: [
                            {
                                title: 'Outsourcing',
                                link: '/outsourcing',
                            },
                            {
                                title: 'Consulting',
                                link: '/consulting',
                            },
                            {
                                title: 'Project',
                                link: '/project',
                            },
                            {
                                title: 'Innovation Lab',
                                link: '/innovation-lab',
                            },
                        ]
                    }
                ]
            },
            {
                title: 'Case studies',
                link: '/case-studies',
                rows: [],
            }
        ]
    },
    {
        id: 3,
        cols: [
            {
                title: 'Case studies',
                link: '/case-studies',
                rows: []
            },
            {
                title: 'Company',
                link: '/company',
                rows: [
                    {
                        title: '',
                        links: [
                            {
                                title: 'About Billennium',
                                link: '/about-billennium',
                            },
                            {
                                title: 'Culture',
                                link: '/culture',
                            },
                            {
                                title: 'History',
                                link: '/history',
                            },
                            {
                                title: 'Board',
                                link: '/board',
                            },
                            {
                                title: 'Press releases',
                                link: '/press-releases',
                            },
                        ]
                    }
                ],
            },
            {
                title: 'Career',
                link: '/career',
                rows: [
                    {
                        title: '',
                        links: [
                            {
                                title: 'Teams',
                                link: '/teams',
                            },
                            {
                                title: 'How to join',
                                link: '/how-to-join',
                            },
                            {
                                title: 'Job offers',
                                link: '/job-offers',
                            },
                            {
                                title: 'Locations',
                                link: '/locations',
                            },
                        ]
                    }
                ],
            }
        ]
    }
];