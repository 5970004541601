import styled from "styled-components";
import { media } from "themes/media";

const Wrapper = styled.div`
    width: 100%;
    box-sizing: border-box;
    padding: 0 30px 0;
    margin: 0 auto;
    .swiper .swiper-wrapper {
        .swiper-slide{
            div{
                position: relative;
                right: -30px;
                opacity: 0;
                transition: opacity 0.5s, right 0.5s;
            }
            &.swiper-slide-active{
                div{
                    right: 0;
                    opacity: 1;
                    transition: opacity 0.5s, right 0.5s;
                    transition-delay: 0.5s;
                }
            }
        }
    }
    ${media.up('lg')} {
        max-width: 1776px;
        padding: 113px 120px 0;
    }
`;

export { Wrapper };