import { Wrapper, Avatar, Content } from "./styles";

const PersonInfo = ({image, name, position}) => (
    <Wrapper>
        <Avatar>
            <img src={image} alt={name} />
        </Avatar>
        <Content>
            <p>{name}<br/><span>{position}</span></p>
        </Content>
    </Wrapper>
);

export { PersonInfo };