import styled from "styled-components";
import { media } from "themes/media";

const Wrapper = styled.div`
    width: 100%;
    box-sizing: border-box;
    margin: 0 0 41px;
    text-align: center;
    ${media.up('lg')}{
        margin: 0 0 116px;
        p{
            text-align: left;
            margin: 0;
            padding: 0;
        }
    }
`;

const Top = styled.div`
    width: 100%;
    margin: 0 0 17px;
    h4{
        max-width: 100%;
    }
    div{
        display: none;
    }
    ${media.up('lg')}{
        margin: 0 0 72px;
        display: flex;
        justify-content: space-between;
        h4{
            text-align: left;
            margin: 0;
        }
        div{
            display: flex;
        }
    }
`;

export { Wrapper, Top };