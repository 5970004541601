import styled from 'styled-components';
import { media } from 'themes/media';

import { IParallax } from './interfaces';

const Wrapper = styled.div`
    width: 100%;
    margin: 48px auto 0;
    padding: 10px 0;
    position: relative;
    overflow: hidden;
    height: 400px;
    max-width: 1920px;
    img{
        position: absolute;
        z-index: 5;
        left: -150px;
        &.desktop{
            display: none;
        }
        &.top{
            top: 0;
        }
        &.bottom{
            bottom: 0;
        }
        ${media.up('sm')}{
            left: 0;
        }
        ${media.up('md')}{
            &.mobile{
                display: none;
            }
            &.desktop{
                display: block;
            }
        }
    }
    ${media.up('md')}{
        height: 586px;
    }
`;

const Back = styled.div`
    width: 100%;
    height: 349px;
    background-color: ${({theme}) => theme.colors.primaryOrange};
    ${media.up('md')}{
        height: 575px;
        padding: 90px 0;
    }
`

const Content = styled.div<IParallax>`
    width: 100%;
    padding: 20px 30px 90px;
    margin: 40px auto;
    text-align: center;
    box-sizing: border-box;
    h4{
        flex: 1 0 calc(100% - 250px);
        font-size: 67px;
        font-weight: 900;
        line-height: 57px;
        letter-spacing: -0.005em;
        text-align: left;
        color: ${({theme}) => theme.colors.black};
        margin: 0 0 40px;
        padding: 0;
        display: inline-block;
        width: auto;
    }
    p{
        text-align: center;
        margin: 0 0 60px;
        padding: 0;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0em;
        color: ${({theme}) => theme.colors.black};
    }
    a{
        text-align: center;
        background: ${({theme}) => theme.colors.white};
        box-shadow: 0px 18px 49px rgba(0, 0, 0, 0.07), 0px 5.42647px 14.7721px rgba(0, 0, 0, 0.0456112), 0px 2.25388px 6.13556px rgba(0, 0, 0, 0.035), 0px 0.815184px 2.21911px rgba(0, 0, 0, 0.0243888);
        border-radius: 5px;
        border: none;
        outline: none;
        margin: 20px auto 0;
        padding: 15px 34px;
        font-size: 18px;
        font-weight: 400;
        line-height: 18px;
        letter-spacing: 0em;
        color: ${({theme}) => theme.colors.black};
        cursor: pointer;
        text-decoration: none;
        ${media.up('lg')}{
            font-size: 20px;
            line-height: 20px;
        }
    }
    ${media.up('sm')}{
        padding-top: 120px;
        display: flex;
    }
    ${media.up('md')}{
        margin: 0 auto;
    }
    ${media.up('lg')}{
        padding: 120px 120px 90px;
        max-width: 1706px;
        position: relative;
        display: table;
        h4{
            font-size: 208px;
            line-height: 177px;
            flex: 1 0 calc(100% - 550px);
        }
        div{
            display: flex;
            flex-flow: row wrap;
            justify-content: end;
            width: 337px;
            position: absolute;
            right: 120px;
            top: 138px;
            p{
                flex: 1 0 100%;
                font-size: 36px;
                line-height: 50px;
                text-align: left;
                margin: 0 0 30px;
            }
            a{
                border-radius: 2px;
                padding: 21px 30px;
                font-size: 20px;
                line-height: 28px;
                margin: 0;
            }
            
        }
    }
    ${media.up('xl')}{
        justify-content: start;
        text-align: left;
        h4{
            max-width: 1040px;
        }
        div{
            width: 600px;
            top: 100px;
            gap: 20px;
            p{
                flex: 0 0 auto;
                margin: 0;
            }
        }
    }
    ${media.up('xxl')} {
        max-width: 1526px;
        padding: 64px 0 90px;
        h4{
            font-size: 238px;
            line-height: 201px;
            max-width: 100%;
        }
        div{
            top: 150px;
            right: 0;
        }
    }
`

export { Wrapper, Back, Content };