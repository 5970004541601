import { AnimationOnScroll } from 'react-animation-on-scroll';
import { Wrapper } from "./styles";

import { FooterHeader } from "components/molecules/FooterHeader";
import { FooterCountries } from "components/molecules/FooterCountries";
import { FooterBottom } from "components/molecules/FooterBottom";
import { FooterList } from "components/molecules/FooterList";

const Footer = () => (
    <Wrapper>
        <AnimationOnScroll animateOnce={true} animateIn="animate__fadeIn" duration={1.5}>
            <FooterHeader />
            <FooterCountries />
            <FooterList />
            <FooterBottom />
        </AnimationOnScroll>
    </Wrapper>
);

export { Footer };