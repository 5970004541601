import styled from 'styled-components';
import { media } from 'themes/media';

const Wrapper = styled.div`
    width: 100%;
    padding: 0 30px;
    margin: 0 auto 65px;
    h4{
        font-size: 30px;
        font-weight: 400;
        line-height: 42px;
        letter-spacing: -0.01em;
        text-align: center;
        margin: 0;
        padding: 0;
        color: ${({theme}) => theme.colors.black};
    }
    ${media.up('lg')} {
        max-width: 900px;
        padding: 0;
        margin: 0 auto 200px;
        h4{
            font-size: 58px;
            line-height: 81px;
        }
    }
`;

export { Wrapper };