import styled from "styled-components";
import { media } from "themes/media";

const Wrapper = styled.div`
    width: 100%;
    margin: 110px 0 0;
    padding: 0;
    ${media.up('lg')} {
        margin: 200px 0 0;
    }
`;

export { Wrapper };