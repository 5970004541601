import styled from "styled-components";
import { media } from "themes/media";
import { ITitle } from "./interfaces";

const Wrapper = styled.h4<ITitle>`
    font-size: ${({version}) => version === 'bigger' ? 39 : 30}px;
    font-weight: 400;
    line-height: ${({version}) => version === 'bigger' ? 54 : 42}px;
    letter-spacing: -0.01em;
    text-align: center;
    margin: 0;
    padding: 0;
    color: ${({theme}) => theme.colors.black};
    max-width: 892px;
    ${media.up('lg')}{
        font-size: 58px;
        line-height: 81px;
        margin: 0 auto;
    }
`;

export { Wrapper };
