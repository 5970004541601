import { Wrapper, Top, Content } from "./styles";

const SoloMilestone = ({image, title, year, content}) => (
    <Wrapper>
        <Top>
            <img src={image} alt={title} />
        </Top>
        <Content>
            {year &&<h6>{year}</h6>}
            <p>{content}</p>
        </Content>
    </Wrapper>
);

export { SoloMilestone };