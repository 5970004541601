import LinkedinIcon from 'assets/icons/linkedin.svg';
import FacebookIcon from 'assets/icons/facebook.svg';
import InstagramIcon from 'assets/icons/instagram.svg';

export const icons = [
    {
        id: 1,
        icon: LinkedinIcon,
        alt: 'linkedin icon',
    },
    {
        id: 2,
        icon: FacebookIcon,
        alt: 'facebook icon',
    },
    {
        id: 3,
        icon: InstagramIcon,
        alt: 'instagram icon',
    }
]