import styled from 'styled-components';
import { media } from 'themes/media';

const Wrapper = styled.div`
    width: 100%;
    padding: 0 30px;
    margin: 0 auto;
    overflow: hidden;
    box-sizing: border-box;
    ${media.up('lg')}{
        padding: 0;
    }
`;

const SliderWrapper = styled.div`
    width: 1200px;
    margin: 0 auto;
    display: none;
    ${media.up('lg')}{
        display: block;
    }
`

const Gallery = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 0 20px;
    align-items: flex-start;
    ${media.up('lg')}{
        display: none;
    }
`;

const Col = styled.div`
    max-width: 147px;
    display: flex;
    gap: 35px;
    flex-wrap: wrap;
    img{
        width: 100%;
        &:nth-child(2){
            max-width: 125px;
        }
    }
`

const Slider = styled.div`
    width: 100%;
    padding: 0 120px;
    box-sizing: border-box;
    flex-wrap: wrap;
    gap: 0 121px;
    align-items: stretch;
    box-sizing: content-box;
    display: flex;

    .solo-slider{
        width: 285px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .solo-slider-longer{
        width: 387px;
    }

    .solo-slider img{
        width: 100%;
        margin-right: 0;
        transition: margin 0.8s;
        transition-timing-function: ease-in-out;
    }

    .slider-move img{
        margin-right: -80px;
        transition: margin 0.8s;
        transition-timing-function: ease-in-out;
    }
`

export { Wrapper, Slider, Gallery, Col, SliderWrapper };